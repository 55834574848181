import React, { Component } from 'react'
import { Form, Input } from "semantic-ui-react";
import { baseURL } from "../../../../configuration/constants";
import axios from "axios";
import { getHeaders, isClinical } from "../../../helper";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';





class FlourishLabelGeneration extends Component {
    state = { label_data: {}, step_current: {}, servings_per_container: '', pro_name: [], calories: {}, calculation: [], other_calculation: [], note: "", pre_names: "", is_capsule: false, allergy_note: "", allergy_note_2: "", customer: {}, best_before: "", made_date: "", product_type: "", pin: "", user: "", step_prev: "flourish_pill_weight", step_next: "pill_packaging_flourish", formErrors: { performed_by_pin: false, confirmed_by_pin: false } }

    componentDidMount() {
        this.getCurrentStep('flourish_label_generation');
        this.labelGeneration();
        let is_clinical = isClinical(this.props.selected_row_value.product_type);

        // if (this.props.selected_row_value.is_prime === 'Yes' && this.props.selected_row_value.flourish_capsule_size === "powder") {
        //     this.setState({ step_next: "equipment_sanitization" })
        // }

        if (!is_clinical) {
            if (this.props.selected_row_value.is_prime === 'No' && this.props.selected_row_value.flourish_capsule_size === "powder") {
                this.setState({ step_prev: "weight_of_mixed_flourish_ingredients" })
            }
            if (this.props.selected_row_value.is_prime === 'Yes' && this.props.selected_row_value.flourish_capsule_size === "powder") {
                this.setState({ step_prev: "weight_of_mixed_prime_ingredients" })
            }
        }
        if (isClinical) {
            if (this.props.selected_row_value.is_prime === 'No' && this.props.selected_row_value.flourish_capsule_size === "powder") {
                this.setState({ step_prev: "weight_of_mixed_flourish_ingredients" })
                //this.setState({step_prev:"pilling_of_mixed_prime_ingredient"})             
            }
        }
    }
    handleClick = () => {
        // Call the function passed from the parent
        this.props.onPrevClick(this.state.step_prev);
    };

    handleNextClick = () => {
        // Call the function passed from the parent
        this.props.onNextClick(this.state.step_next);
    };

    handlePauseClick = () => {
        this.props.onNextClick('pause');
    }
    handleQuitClick = () => {
        this.props.onNextClick('quit');
    }

    getCurrentStep = (step_name) => {
        console.log(step_name)
        let req = {
            formula_id: this.props.selected_row_value.formula_id,
            step_name: step_name
        }
        axios.post(baseURL + "/get-current-step", req, getHeaders())
            .then(res => {
                let result = res.data;
                if (result.success === true && result.current_step !== null) {
                    let current_step = result.current_step;
                    let step_json = JSON.parse(current_step.step_json);
                    console.log(step_json);
                    this.setState({ step_current: current_step });
                    this.onInputChange(null, { name: "performed_by_pin", "value": current_step.performed_by });
                    this.onInputChange(null, { name: "confirmed_by_pin", "value": current_step.confirmed_by });

                }
            })
    }

    labelGeneration = () => {
        let req = {
            sample_id: this.props.selected_row_value.sample_id,
            formulation_id: this.props.selected_row_value.id
        }
        axios.post(baseURL + "/label-generation", req, getHeaders())
            .then(res => {
                let result = res.data;
                if (result.success === true && result.label !== null) {
                    let label = JSON.parse(result.label);
                    this.setState({ label_data: label[0] });
                    let label_data = label[0].data;
                    this.setState({ qc_weight: label_data.qc_weight });
                    this.setState({ servings_per_container: label_data.servings_per_container });
                    this.setState({ pro_name: label_data.pro_names });
                    this.setState({ calories: label_data.calories[0] });
                    this.setState({ calculation: label_data.calculation });
                    this.setState({ other_calculation: label_data.other_calculation });
                    this.setState({ note: label_data.note });
                    this.setState({ pre_names: label_data.pre_names ? label_data.pre_names.join(',') : "" });
                    this.setState({ is_capsule: label_data.is_capsule });
                    this.setState({ allergy_note: label_data.allergy_note });
                    this.setState({ allergy_note_2: label_data.allergy_note_2 });
                    this.setState({ customer: label_data.user });
                    this.setState({ product_type: label_data.display_product_type });
                    this.setState({ best_before: moment(label_data.best_before).format('MM/DD/YYYY') });
                    this.setState({ made_date: moment(label_data.date_made).format('MM/DD/YYYY') });
                }
            })

    }

    onInputChange = (e, { name, value }) => {
        console.log(name);
        switch (name) {
            case 'performed_by_pin':
                if (value.length >= 3) {
                    if (this.state.confirmed_by_pin !== value) {
                        this.getPerformUser(value, 'performer');
                    } else {
                        toast.error("Please enter the another pin");
                    }
                } else {
                    this.setState({ performed_by: "", performed_by_name: "" });
                }
                break;
            case 'confirmed_by_pin':
                if (value.length >= 3) {
                    if (this.state.performed_by_pin !== value) {
                        this.getPerformUser(value, 'confirmer');
                    } else {
                        toast.error("Please enter the another pin");
                    }
                } else {
                    this.setState({ confirmed_by: "", confirmed_by_name: "" });
                }
                break;
            default:
                break;
        }

        this.setState({ [name]: value });

    };

    getPerformUser = (pin, user_type) => {
        if (!pin) {
            toast.error("Please fill pin number.");
            return;
        }
        axios.post(baseURL + "/users/getUserByPin", { pin: pin }, getHeaders())
            .then(res => {
                if (res.data.success === true && res.data.user !== null) {
                    let user = res.data.user;
                    if (user_type === 'performer') {
                        this.setState(prevState => ({
                            performed_by: user ? user.id : "",
                            performed_by_name: user ? user.full_name : "",
                            formErrors: {
                                ...prevState.formErrors,
                                performed_by_pin: user ? false : true
                            }
                        }));

                    } else {
                        this.setState(prevState => ({
                            confirmed_by: user ? user.id : "",
                            confirmed_by_name: user ? user.full_name : "",
                            formErrors: {
                                ...prevState.formErrors,
                                confirmed_by_pin: user ? false : true
                            }
                        }));
                    }

                    //this.setState({user_full_name:user.full_name}); 
                }
            })
    }

    handleSubmit = () => {
        this.setState((prevState) => ({
            formErrors: {
                ...prevState.formErrors, // spread the existing formErrors
                performed_by_pin: !this.state.performed_by_name ? true : false,
                confirmed_by_pin: !this.state.confirmed_by_name ? true : false
            }
        }));
        if (!this.state.confirmed_by_pin || !this.state.performed_by_pin) {
            toast.error("PIN is missing");
            return;
        }

        if ((!this.state.performed_by_name && this.state.performed_by_pin) ||
            (!this.state.confirmed_by_name && this.state.confirmed_by_pin)) {
            toast.error("PIN is incorrect");
            return;
        }
        let param = {
            sample_id: this.props.selected_row_value.sample_id,
            formula_id: this.props.selected_row_value.formula_id,
            step: 20,
            step_json: "",
            step_name: 'flourish_label_generation',
            step_next: this.state.step_next,
            performed_by: this.state.performed_by_pin,
            confirmed_by: this.state.confirmed_by_pin
        }
        axios.post(baseURL + "/mmr-formulations", param, getHeaders())
            .then(res => {
                let result = res.data;
                if (result.success === true) {
                    toast.success("Successfully saved Step");
                    this.props.onNextStep();
                } else {
                    toast.error("Step has not saved");
                }
            })
    }
    render() {
        console.log(this.state);
        const { formErrors } = this.state;
        return (
            <>
                <Form onSubmit={this.handleSubmit}>
                    <div className='cs-box'>
                        <div className='cs-body'>
                            <h3>Label Generation</h3>
                            <div className='mt-2'>
                                <ul className='cs-list'>
                                    <li>Review finalized nutrition label for:
                                        <ul className='cs-list'>
                                            <li>Correct fmrx and formula identifiers</li>
                                            <li>Correct serving size, to match QC weight check.</li>
                                            <li>Correct formulation contents</li>
                                            <li>Proper display of daily value for possible vitamins or other nutritional values</li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                            <div className='mt-5'>
                                <h3>Representative Labels</h3>
                                <h4 className='text-center'>Supplement Facts Label</h4>
                                <div className='sf-box'>
                                    <div className='sf-inner-box'>
                                        <div className='sf-heading1'>Supplement Facts</div>
                                        <div className='sf-heading2'>Serving Size {this.state.qc_weight}</div>
                                        <div className='sf-heading2'>Servings Per Containers {this.state.servings_per_container}</div>
                                        <div className='sf-table'>
                                            {/* ---Headers---- */}
                                            <table className='w-100' cellPadding={0} cellSpacing={0}>
                                                <tr>
                                                    <th></th>
                                                    <th>Amount Per Serving</th>
                                                    <th>%DV*</th>
                                                </tr>
                                                <tbody>
                                                    {
                                                        this.state.pro_name.length > 0 && this.state.pro_name && (<tr>
                                                            <td>Probiotic Blend</td>
                                                            <td>{this.state.label_data.data.total_pro_amount}g ({this.state.label_data.data.probiotic_blend} Billing CFU✝)</td>
                                                            <td></td>
                                                        </tr>)



                                                    }
                                                    {/* -- Display PRO Ingredients -- */}
                                                    {

                                                        this.state.pro_name.length > 0 && this.state.pro_name.map((item) => {
                                                            return (
                                                                <tr>
                                                                    <td>{item}</td>
                                                                    <td></td>
                                                                    <td>**</td>
                                                                </tr>

                                                            )
                                                        })
                                                    }

                                                    {/* -- Display Calories -- */}
                                                    {
                                                        Object.keys(this.state.calories).length > 0 && this.state.calories.total_value > 0 && (
                                                            <tr>
                                                                <td>{this.state.calories.supplement}</td>
                                                                <td>{this.state.calories.total_value > 0 ? this.state.calories.total_value : ""}</td>
                                                                <td></td>
                                                            </tr>

                                                        )
                                                    }
                                                    {/* -- Display PRE Ingredients -- */}
                                                    {/* -- ------------ Level 1 ------------ -- */}
                                                    {
                                                        Object.keys(this.state.calculation).length > 0 && this.state.calculation && this.state.calculation.map((item) => {
                                                            var dv_value = item.dv_value > 0 && item.dv_value !== '**' ? item.dv_value + "%" : "";
                                                            return (
                                                                item.total_value > 0 && (item.supplement === 'Total Fat' || item.supplement === 'Sodium' || item.supplement === 'Cholesterol' || item.supplement === 'Total Carbohydrates') && (
                                                                    <tr>
                                                                        <td>{item.supplement}</td>
                                                                        <td>{item.total_value} {item.unit}</td>
                                                                        <td>{dv_value}</td>
                                                                    </tr>)

                                                            )
                                                        })
                                                    }

                                                    {/* -- ------------ Level 2 ------------ -- */}
                                                    {
                                                        Object.keys(this.state.calculation).length > 0 && this.state.calculation && this.state.calculation.map((item) => {
                                                            var dv_value = item.dv_value > 0 && item.dv_value !== '**' ? item.dv_value + "%" : "";
                                                            return (
                                                                item.total_value > 0 && (item.supplement === 'Total Fat' || item.supplement === 'Saturated Fat' || item.supplement === 'Dietary Fiber') && (
                                                                    <tr>
                                                                        <td>{item.supplement}</td>
                                                                        <td>{item.total_value} {item.unit}</td>
                                                                        <td>{dv_value}</td>
                                                                    </tr>)

                                                            )
                                                        })
                                                    }

                                                    {/* -- ------------ Level 3 ------------ -- */}
                                                    {
                                                        Object.keys(this.state.calculation).length > 0 && this.state.calculation && this.state.calculation.map((item) => {
                                                            var dv_value = item.dv_value > 0 && item.dv_value !== '**' ? item.dv_value + "%" : "";
                                                            return (
                                                                item.total_value > 0 && (item.supplement === 'Total Sugars' || item.supplement === 'Including Added Sugars') && (
                                                                    <tr>
                                                                        <td>{item.supplement}</td>
                                                                        <td>{item.total_value} {item.unit}</td>
                                                                        <td>{dv_value}</td>
                                                                    </tr>)

                                                            )
                                                        })
                                                    }
                                                    {/* -- ------------ Others (Split) ------------ -- */}

                                                    {
                                                        Object.keys(this.state.calculation).length > 0 && this.state.calculation && this.state.calculation.map((item) => {
                                                            var dv_value = item.dv_value > 0 && item.dv_value !== '**' ? item.dv_value + "%" : "";
                                                            return (
                                                                item.total_value > 0 && item.supplement_type === 'nutrient' && (item.supplement !== 'Cholesterol' && item.supplement !== 'Total Carbohydrates' && item.supplement !== 'Total Sugars' && item.supplement !== 'Dietary Fiber' && item.supplement !== 'Including Added Sugars' && item.supplement !== 'Total Fat' && item.supplement !== 'Sodium' && item.supplement !== 'Saturated Fat' && item.supplement !== 'Trans Fat') && (
                                                                    <tr>
                                                                        <td>{item.supplement}</td>
                                                                        <td>{item.total_value} {item.unit}</td>
                                                                        <td>{dv_value}</td>
                                                                    </tr>)

                                                            )
                                                        })
                                                    }

                                                    {
                                                        Object.keys(this.state.calculation).length > 0 && this.state.calculation && this.state.calculation.map((item) => {
                                                            var dv_value = item.dv_value > 0 && item.dv_value !== '**' ? item.dv_value + "%" : "";
                                                            return (
                                                                item.total_value > 0 && item.supplement_type !== 'nutrient' && (item.supplement !== 'Cholesterol' && item.supplement !== 'Total Carbohydrates' && item.supplement !== 'Total Sugars' && item.supplement !== 'Dietary Fiber' && item.supplement !== 'Including Added Sugars' && item.supplement !== 'Total Fat' && item.supplement !== 'Sodium' && item.supplement !== 'Saturated Fat' && item.supplement !== 'Trans Fat') && (
                                                                    <tr>
                                                                        <td>{item.supplement}</td>
                                                                        <td>{item.total_value} {item.unit}</td>
                                                                        <td>{dv_value}</td>
                                                                    </tr>)

                                                            )
                                                        })
                                                    }
                                                    {/* -- ------------ Nutrients w/o RDI DRV ------------ -- */}
                                                    {
                                                        this.state.other_calculation.length > 0 && this.state.other_calculation.map((item) => {
                                                            return (
                                                                item.total_value > 25 && (
                                                                    <tr>
                                                                        <td>{item.supplement}</td>
                                                                        <td>{item.total_value} {item.unit}</td>
                                                                        <td>**</td>
                                                                    </tr>)

                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className='sf-heading3'>
                                            {
                                                this.state.note && this.state.note !== "" && (<span>*{this.state.note}<br /></span>)
                                            }
                                            ** Daily Value (DV) not established<br />
                                            ✝At the time of manufacture
                                        </div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='cs-w-60'>
                                            {
                                                this.state.pre_names !== "" && (<div className='sf-heading3 p-0 mb-1'>Ingredients: {this.state.pre_names}</div>)
                                            }
                                            {
                                                this.state.is_capsule === true && (<div className='sf-heading3 p-0 mb-1'>Other Ingredients: Vegan Capsule (HPMC and Water)</div>)
                                            }

                                            <div className='sf-heading3 p-0 mb-1'><strong>Warning:</strong> consult your physician before using any product if you are pregnant, nursing, trying to conceive, taking medication or have a medical condition.</div>
                                            {
                                                this.state.allergy_note !== "" && (<div className='sf-heading3 p-0 mb-1'><strong>{this.state.allergy_note} {this.state.allergy_note_2}</strong></div>)
                                            }


                                        </div>
                                        <div className='cs-w-40'>
                                            <div className='sf-right-box mb-1'>
                                                {
                                                    this.state.customer && Object.keys(this.state.customer).length > 0 && (
                                                        <div className='sf-heading3 p-0 text-center'>
                                                            <div>{this.state.customer.sg_number}</div>
                                                            <div>{this.state.customer.formula_id}</div>
                                                            <div><strong>Best if Used By:</strong></div>
                                                            <div>{this.state.best_before}</div>
                                                        </div>
                                                    )
                                                }

                                            </div>
                                            <div className='sf-right-box'>
                                                <div className='sf-heading3 p-0 text-center'>
                                                    Manufactured by Floré <br />
                                                    5151 Shoreham Pl <br />
                                                    San Diego, CA 92122, <br />
                                                    USA
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='text-center mt-5'>
                                <div className='d-inline-flex'>
                                    <div className='d-flex align-items-center'>
                                        <div><h4 className='m-0 pe-1'>Performed By: </h4></div>
                                        <div className='m-0 pe-1'>
                                            <h4 className='m-0'><u>{this.state.performed_by_name}</u></h4>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <div><h4 className='m-0 pe-1'> PIN</h4></div>
                                        <div className='ui input'>
                                            <Form.Field required fluid="true">
                                                <Input
                                                    error={formErrors.performed_by_pin}
                                                    id="performed_by_pin"
                                                    name="performed_by_pin"
                                                    onChange={this.onInputChange}
                                                    value={this.state.performed_by_pin}
                                                    placeholder="Enter Your PIN"
                                                    type="password"
                                                    className="full_opacity"
                                                    autoComplete="new-password"
                                                />
                                            </Form.Field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='text-center mt-2 mb-5'>
                                <div className='d-inline-flex'>
                                    <div className='d-flex align-items-center'>
                                        <div><h4 className='m-0 pe-1'>Confirmed By: </h4></div>
                                        <div className='m-0 pe-1'>
                                            <h4 className='m-0'><u>{this.state.confirmed_by_name} </u></h4>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <div><h4 className='m-0 pe-1'> PIN</h4></div>
                                        <div className='ui input'>
                                            <Form.Field required fluid="true">
                                                <Input
                                                    error={formErrors.confirmed_by_pin}
                                                    id="confirmed_by_pin"
                                                    name="confirmed_by_pin"
                                                    onChange={this.onInputChange}
                                                    value={this.state.confirmed_by_pin}
                                                    placeholder="Enter Your PIN"
                                                    type="password"
                                                    className="full_opacity"
                                                    autoComplete="new-password"
                                                />
                                            </Form.Field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='cs-footer text-center'>
                            <hr />
                            <div className='text-center mt-2'>
                                <button type='button' onClick={this.handleClick} className='gray-btn' >Prev</button>
                                <button type='button' onClick={this.handlePauseClick} className='ui icon secondary labeled button pause-btn ps-1-5em ms-2'>Pause</button>
                                <button type='button' onClick={this.handleQuitClick} className='ui icon secondary labeled button quit-btn ps-1-5em ms-2'>Quit</button>
                                {this.props.step_name === this.state.step_prev && (<button type='submit' className='theme-btn ms-2' >Next</button>)}
                                {this.props.step_name !== this.state.step_prev && (<button type="button" onClick={this.handleNextClick} className="theme-btn ms-2" > Next </button>)}
                            </div>
                        </div>
                    </div>
                </Form>
            </>
        )
    }
}
export default FlourishLabelGeneration;