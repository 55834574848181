//-------------------------------------------------------------------//
// Module: Formulations                                              //
// SubModule: CreateFormulation                                      //
// SubSubModule: StartFormulation                                    //
// Tasks: 1) Displays Modal for StartFormulation                     //
//        2) Manages PrimeInfo, FlourishInfo,                        //
//           FlourishOrganism, and SummaryInfo                       //
// Author: Yash P Shah                                               //
//-------------------------------------------------------------------//

import SummaryInfo from "./SummaryInfo";
import PrimeInfo from "./PrimeInfo";
import FlourishInfo from "./FlourishInfo";
import PdfAfterStartFormulation from "./pdfStartFormulation";
import Doc from "../DocServiceStartFormulation";
import {
  focusFirstElement,
  customPrint,
  getHeaders,
  getSessionStoredJsonItem,
  convertInventoryOptionsJSON
} from "../../helper";
import CustomLoader from "../../custom_elements/CustomLoader";
import { baseURL, PRINT_CATEGORIES } from "../../../configuration/constants";

import React, { Component } from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Button,
  Icon,
  Modal,
  Segment,
  Grid,
  Form,
  Popup
} from "semantic-ui-react";
import axios from "axios";
import ShowModal from "../../Pop Up/Modal";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/all-modules";

const bodyRef = React.createRef();
let warning = '';
let popUp_description="";

class StartFormulationModal extends Component {

  is_mounted = false;
  popUp_description="";
  warning="";

  constructor(props) {

    super(props);
    if (!this.props.selected_row_value) {
      return "Error 404: No Selected Row Data Received!";
    }
  }
  state = {
    calories: "",
    modalOpen: false,
    converted_inventory_options_json: [],
    organism_alert:false,
    sortOrganism: "",
    show: false,
    probiotic_blend: "",
    currentAge: "",
    qc_weight: "",
    pre_names: "",
    pro_names: "",
    downloadData: "",
    is_popUp_disabled:false,
    servings_per_container: "",
    qc_out_of_spec:0,
    prime_filer_inventory_id:'',
    accept_total_capsule_size_popup:false,
    sample_id:""
  };

  openPdf = async () => {

    this.setState({ is_page_loading: true });
    const res = await axios.post(baseURL + "/generate-label-pdf",
      [{ sg_number: this.props.selected_row_value.sample_id, id: this.props.selected_row_value.id }]
      , getHeaders());
    if (res.data.success) {
      window.open(res.data.data, '_blank');
    }
    else {
      toast.error("Something Went Wrong!");
    }

    this.setState({ is_page_loading: false });
  }

  createPdf = (html) => {
    Doc.createPdf(html, this.props.selected_row_value.formula_id);
  }

  next = () => {

    this.props.onStateChange({
      defaultValue: false,
      modalOpen: false,
      is_updating_failed: false,
      is_updating_successful: false,
      is_waiting_for_response: true
    });

     let res = {
      id: this.props.selected_row_value.id,
      date_made: this.props.selected_row_value_date_made,
      prime_fill_qc_weight: this.props.selected_row_value.prime_fill_qc_weight,
      prime_powder_remaining: this.props.selected_row_value.prime_powder_remaining,
      prime_total_capsules_made: this.props.selected_row_value.prime_total_capsules_made,
      flourish_powder_remaining: this.props.selected_row_value.flourish_powder_remaining,
      flourish_total_capsules_made: this.props.selected_row_value.flourish_total_capsules_made,
      operator_notes: this.props.selected_row_value.operator_notes,
      flourish_fill_qc_weight: this.props.selected_row_value_qc_weight.flourish_fill_qc_weight,
      filled_tube: 
      
      this.props.selected_row_value_filled_tube.filled_tube === "" ||
      this.props.selected_row_value_filled_tube.filled_tube === undefined
      || this.props.selected_row_value_filled_tube.filled_tube === null
      ? "" :this.props.selected_row_value_filled_tube.filled_tube,
      
      filled_spoon:this.props.spoon_value, 
      qc_out_of_spec:this.state.qc_out_of_spec,
      is_completed: 1,
      user_id: getSessionStoredJsonItem("user").user_id
    }

    customPrint(
      "Custom Modal",
      PRINT_CATEGORIES.REQUEST,
      baseURL + "/formulations"
    );

    axios
      .put(baseURL + "/formulations/" + res.id, res, getHeaders())
      .then(response => {
        customPrint("Custom Modal", PRINT_CATEGORIES.RESPONSE, response);
        this.props.onStateChange({
          is_updating_successful: response.data.success,
          is_updating_failed: !response.data.success,
          response_message: response.data.message
        });
        // this.onCloseModalClick();

        this.openPdf()
        focusFirstElement();

        setInterval(() => {
          this.setState({
            is_page_loading: false,
            is_waiting_for_response: false,
            is_message_visible: true,
          })
        }, 9000)


        setInterval(() => {
          window.location.reload(false);
        }, 9000)

      })
      .catch(error => {
        alert("Saving Custom Modal | Error: " + error);
        this.props.onStateChange({
          is_updating_failed: true,
          response_message: "Error in Updating Formulation",
          is_waiting_for_response: false
        });
      });
  }

  onUpdateClick = () => {

    var prime_filler_inventory_id = this.props.selected_row_value.prime_filler_inventory_id
    var flourish_capsule_size = this.props.selected_row_value.flourish_capsule_size
    var flourish_total_capsule_size = this.props.selected_row_value.flourish_total_capsules_made

    var flourish_powder_remaining = this.props.selected_row_value.flourish_powder_remaining;
    var prime_powder_remaining = this.props.selected_row_value.prime_powder_remaining;

    var flourish_fill_qc_weight = this.props.selected_row_value_qc_weight.flourish_fill_qc_weight
    var prime_fill_qc_weight = this.props.selected_row_value.prime_fill_qc_weight

    var prime_total_capsules_made = this.props.selected_row_value.prime_total_capsules_made
    var accept_total_capsule_size_popup  = this.state.accept_total_capsule_size_popup
    var number_of_months  = this.props.number_of_months;

    var flourish  = (flourish_total_capsule_size == " " || flourish_total_capsule_size == null);
    var prime  = (prime_filler_inventory_id !== null && (prime_total_capsules_made == " " || prime_total_capsules_made == null));
    

    if(flourish || prime ){
      var section = "";
      section  = (flourish && prime) && "Prime & Flourish";
      section  = (prime) ? "Prime":(flourish) ? "Flourish":"";
      alert(section + " Total Capsule size should not be blank");
      return;
    }
    if((flourish_powder_remaining  == "" 
      || flourish_powder_remaining == null)
      || (prime_filler_inventory_id !==null &&
         (prime_powder_remaining  == "" || prime_powder_remaining == null)) ) 
    {
      alert(" Powder remaining can't be empty");
      return;
    }

    if( prime_filler_inventory_id !== null && (prime_fill_qc_weight == "" || prime_fill_qc_weight == null))
    {
      alert("Please fill prime QC Weight");      
      return;
    }
    if( flourish_fill_qc_weight == undefined || flourish_fill_qc_weight == "")
    {
      alert("Please fill flourish QC Weight");      
      return;
    }

    var flag = true;
    this.state.sortOrganism && this.state.sortOrganism.map((item,index)=>{
      if(item.qty<0 && !this.state.organism_alert){
        flag = window.confirm("Are you sure you want to mark this formula/refill as made?");
        this.setState({organism_alert:flag}); 
      };
    })
    if(!flag) return;

    var check_value  = ((this.props.number_of_months *31)+4)

  if(flourish_capsule_size !== "powder"){
    if( number_of_months == 3 && !accept_total_capsule_size_popup &&
      ( (parseInt(flourish_total_capsule_size) < check_value || parseInt(flourish_total_capsule_size) > 100) ))
      {
        warning = "total_capsule_size";
        popUp_description="Please confirm everything was input correctly";
        this.setState({is_popUp_disabled:true});
        return;
      }

      if( number_of_months && !accept_total_capsule_size_popup
         && (parseInt(flourish_total_capsule_size) < check_value || parseInt(flourish_total_capsule_size) > 204))
        {
          warning = "total_capsule_size";
          popUp_description="Please confirm everything was input correctly";
          this.setState({is_popUp_disabled:true});
          return;
        }
    
      if(!accept_total_capsule_size_popup &&
        ( (parseInt(flourish_total_capsule_size) < check_value)  || (parseInt(flourish_total_capsule_size) < check_value)
        ) )
        {
          warning = "total_capsule_size";
          popUp_description="Please confirm everything was input correctly";
          this.setState({is_popUp_disabled:true});
          return;
        }
      }

     if ((
      (
        ["0","1"].indexOf(flourish_capsule_size) !== -1 &&
        ( flourish_fill_qc_weight < 0.38 || flourish_fill_qc_weight > 0.5))
        ||(["0","1"].indexOf(prime_total_capsules_made) !== -1 && 
        prime_filler_inventory_id !== null && (prime_fill_qc_weight < 0.38 || prime_fill_qc_weight > 0.5))
      )
     )
    {
      warning = "Qc Weight";
      popUp_description="The QC weight spec is out of range. Please confirm it is inputted correctly!";
      this.setState({is_popUp_disabled:true});
      return;
    }
    else{
      this.next();
      this.setState({is_popUp_disabled:false});
    }
    
    this.onOpenModalClick();
    this.setState({ defaultValue: true })
 

    
  };

  //handles click on cancel button
  onCancelClick = () => {
    this.onCloseModalClick();
  };

  componentDidMount() {
    focusFirstElement();
    this.is_mounted = true;

    // API call to get the inventory options
    customPrint(
      "Start Formulation Modal",
      PRINT_CATEGORIES.REQUEST,
      baseURL + "/inventory/inventoryOptions"
    );

    axios
      .get(baseURL + "/inventory/inventoryOptions", getHeaders())
      .then(response => {
        customPrint(
          "Start Formulation Modal",
          PRINT_CATEGORIES.RESPONSE,
          response
        );

        const inventory_options_json = response.data;
        const converted_inventory_options_json = convertInventoryOptionsJSON(
          inventory_options_json
        );

        if (this.is_mounted) {
          this.setState({
            converted_inventory_options_json
          });
        }
      })
      .catch(error => {
        alert("Fetching Inventory Options | Error: " + error);
      });
  }


  componentWillUnmount() {
    this.is_mounted = false;
  }

  onOpenModalClick = () => {
    this.setState({ modalOpen: true });
    axios
      .get(baseURL + "/sortInventory?formula_id=" + this.props.shareSorting, getHeaders())
      .then(res => {
        this.setState({
          sortOrganism: res.data
        })
      })
  };

  onCloseModalClick = () => {
    this.setState({ modalOpen: false });
  };
  Modal_return_value = (value, message) => {
    if (value) {
      if(warning == "total_capsule_size")
      {
        if(value){
         this.setState({accept_total_capsule_size_popup:value})
         warning = "";
         this.callQcWeightPopup();      
          return;
        }
        this.setState({accept_total_capsule_size_popup:value})
        return;
      }
      else{
        this.callQcWeightPopup();      
    }
    } else {
      warning = "";
      return;
    }
  };
  // set pop_up value
  pop_up_open = () => {
    this.setState({ is_popUp_disabled: false });
  };
  callQcWeightPopup(){
    var flourish_fill_qc_weight = this.props.selected_row_value_qc_weight.flourish_fill_qc_weight;
    var flourish_capsule_size  = this.props.selected_row_value.flourish_capsule_size;
    var prime_capsule_size  = this.props.selected_row_value.prime_capsule_size;
    var prime_fill_qc_weight  = this.props.selected_row_value.prime_fill_qc_weight;
    var prime_filler_inventory_id  = this.props.selected_row_value.prime_filler_inventory_id;
    
    setTimeout(() => {
      if ( (
          ["0","1"].indexOf(flourish_capsule_size) !== -1 && (
             flourish_fill_qc_weight < 0.38 || flourish_fill_qc_weight > 0.5)
          || ( ["0","1"].indexOf(prime_capsule_size) !== -1 && 
          prime_filler_inventory_id !== null
           &&( prime_fill_qc_weight < 0.38 || prime_fill_qc_weight > 0.5 ) ) )  && warning == "" )
         {
          popUp_description="The QC weight spec is out of range. Please confirm it is inputted correctly!";
          warning="QC Weight";
          this.setState({is_popUp_disabled:true});
          this.setState({ is_popUp_disabled: true, popUp_description: "" });
        return;
      } else {
        this.setState({qc_out_of_spec:1,is_popUp_disabled: false})
        this.next();
      }
      return;
  }, 500);
  }

  render() {
    let { selected_row_value,spoon_value, selected_row_value_qc_weight, selected_row_value_filled_tube, selected_row_value_date_made,permission } = this.props;
    return (
      <React.Fragment>

        {this.state.is_popUp_disabled && (
          <ShowModal
            message={warning}
            Description={popUp_description}
            Modal_return_value={this.Modal_return_value}
            pop_up_open={this.pop_up_open}
            modal_style={"500px"}
          />
        )}
      <Modal

          trigger={

            <Button
              style={{ fontWeight: "initial" }}
              secondary
              onClick={this.onOpenModalClick}
              icon
              labelPosition="right"
              disabled={this.props.is_special_button_disabled}
            >
              Start Formulation
              <Icon name="chevron circle right" />
            </Button>
          }
          open={this.state.modalOpen}
          onClose={this.onCloseModalClick}
          centered={false}
        >
          {this.props.is_waiting_for_response && (
            <CustomLoader loading_text="Updating Formulation Data" />
          )}
          <Modal.Header className="modal_header">
            {selected_row_value.formula_id}
          </Modal.Header>
          <Modal.Description>
            <Segment>
              <Form>
                <Grid columns="equal">
                  {/* Summary */}
                  <SummaryInfo
                    sample_id={selected_row_value.sample_id}
                    formula_id={selected_row_value.formula_id}
                    number_of_months={selected_row_value.number_of_months}
                    date_made={selected_row_value_date_made}
                    date_created={selected_row_value.date_created}
                    formulation_factor={selected_row_value.formulation_factor}
                    // functions
                    onModalValueChange_Date={this.props.onModalValueChange_Date}
                    permission={permission}
                  />
                  <Grid.Row />
                {selected_row_value.prime_filler_inventory_id && (
                  <PrimeInfo
                    prime_flourish_amount_added={
                      selected_row_value.prime_flourish_amount_added
                    }
                    prime_filler_inventory_id={
                      selected_row_value.prime_filler_inventory_id
                    }
                    converted_inventory_options_json={
                      selected_row_value.inventory_barcode[0].organism_barcode
                    }
                    prime_filler_amount_added={
                      selected_row_value.prime_filler_amount_added
                    }
                    prime_total_amount_added={
                      selected_row_value.prime_total_amount_added
                    }
                    prime_fill_qc_weight={
                      selected_row_value.prime_fill_qc_weight
                    }
                    prime_capsule_size={selected_row_value.prime_capsule_size}
                    prime_total_capsules_made={
                      selected_row_value.prime_total_capsules_made
                    }
                    prime_powder_remaining={
                      selected_row_value.prime_powder_remaining
                    }
                    //functions
                    onModalValueChange={this.props.onModalValueChange}
                    permission={permission}
                    />
                 )}
                  <Grid.Row />
                  {/* Flourish */}
                  <FlourishInfo
                    flourish_fill_qc_weight={
                      selected_row_value_qc_weight.flourish_fill_qc_weight
                    }
                    filled_tube={selected_row_value_filled_tube.filled_tube}

                    flourish_capsule_size={
                      selected_row_value.flourish_capsule_size
                    }
                    flourish_powder_remaining={
                      selected_row_value.flourish_powder_remaining
                    }
                    flourish_total_capsules_made={
                      selected_row_value.flourish_total_capsules_made
                    }
                    // organisms={selected_row_value.organisms}
                    organisms={this.state.sortOrganism}
                    admin_notes={selected_row_value.admin_notes}
                    operator_notes={selected_row_value.operator_notes}
                    total_final_amount_added={
                      selected_row_value.total_final_amount_added
                    }
                    //functions
                    onModalValueChange={this.props.onModalValueChange}
                    onModalValueChangeQcWeight={this.props.onModalValueChangeQcWeight}
                    onModalValueChangePill={this.props.onModalValueChangePill}
                    permission={permission}
                    qa_notes={this.props.qa_notes}
                    spoon = {this.props.spoon_value}
                  />
                  <Grid.Row />
                </Grid>
              </Form>
            </Segment>
          </Modal.Description>

          <Modal.Actions>
            <Grid textAlign="center">
              {/* <Grid.Row /> */}
              <Grid.Row>
                <Grid.Column>

                  <Button.Group>

                    <Popup
                      trigger={
                        <Button
                          content="Formulation made"
                          icon="check"
                          labelPosition="left"
                          positive
                          onClick={this.onUpdateClick}
                        />
                      }
                      content="Update the Formulation Information."
                    />

                    <Button.Or />
                    onCancelClick
                    <Popup
                      trigger={
                        <Button
                          content="Cancel"
                          icon="ban"
                          labelPosition="right"
                          negative
                          onClick={this.onCancelClick}
                        />
                      }
                      content="Discard the Formulation Information Update."
                    />
                  </Button.Group>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row />
            </Grid>
          </Modal.Actions>

          <div ref={bodyRef}>
            <div style={{ display: this.state.show ? 'block' : 'none' }}>
              <PdfAfterStartFormulation downloadData={this.state.downloadData} pre_names={this.state.pre_names} pro_names={this.state.pro_names}
                qc_weight={this.state.qc_weight} servings_per_container={this.state.servings_per_container}
                probiotic_blend={this.state.probiotic_blend}
                currentAge={this.state.currentAge}
                calories={this.state.calories}
                createPdf={this.createPdf} />
            </div>
          </div>
        </Modal>

      </React.Fragment>
    );

  }
}

export default StartFormulationModal;
