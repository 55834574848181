import Config from "../../configuration/config";
import React, { Component } from "react";
import {
  focusFirstElement,
  getDateString,
  customPrint,
  convertReqdInventoryOptionsJSON,
  getHeaders,
  getSessionStoredJsonItem,
  isFormValid,
  getUserRole,
  simplifyModulePermissionData,
  digitsAfterPointOrganism,
  digitsAfterPrimeFlourish,
  prime_filler_amount_added,
  payload_product_type,
  product_type,
  array_product_type,
  Product_Type,
  validation_product_type,
  product_type_probiotics_validation,
  month_wise_validation,
  product_type_3_month,
  product_type_six_twelve_validation,
  probiotic_product_type,
  six_twelve_month_wise_validation,
  Clinical_Prebiotic
} from "../helper";
import {
  getPrimeTotalAmountAdded,
  getFormulationFactor,
  getFinalAmountAdded,
  getTotalFinalAmountAdded,
  getTotalCFU,
  getFinalConcentration,
  getScrapAmount,
  getScrap,
  getTotalFinalConcentration,
  getTotalAmountAdded,
  getFormulationFactorForClinicalFormula,
} from "./Formulas";
import { baseURL, PRINT_CATEGORIES } from "../../configuration/constants";
import TotalRefillInfo from "./TotalRefillInfo";
import ReFormulationInfoRefill from "./ReformulationInfoRefill";
import SuccessMessage from "../custom_elements/SuccessMessage";
import ErrorMessage from "../custom_elements/ErrorMessage";
import CustomLoader from "../custom_elements/CustomLoader";
import CustomDimmer from "../custom_elements/CustomDimmer";
import Flourish from "./Reformulation/Flourish";
import PrimeRefill from "./PrimeRefill";


import axios from "axios";
import { Form, Segment, Header, Message, Grid, ItemDescription } from "semantic-ui-react";
import _ from "lodash";
import "react-datepicker/dist/react-datepicker.css";
import {
  selectedSample,
  selectedFormula,
  sampleData,
  selectedProduct,
} from "./ReformulationInfoRefill";
// import ShowModal from "../Pop Up/Modal";
import ShowModal from "../Pop Up/Modal";
import { toast } from "react-toastify";

export { sample_Id };
let sample_Id;
let totalConcentration = 0;
let totalAmmountAdded = 0;
let totalGain = 0;
let profit = 0;
let type;
let submit_event;
let formulation_factor = 0;
let Organism_obj = {
  preadd_totalAmountAdded: 0,
  preadd_total_cfu: 0,
  manuallyAdd_total_amount_added: 0,
  manuallyAdd_total_cfu: 0,
};

const options = [{ value: 0 }, { value: 1 }, { value: "powder" }];

const payementOptions = [{ payment_type: "Paid" }, { payment_type: "Free" }];

let warning = "";
let popUp_description = "";
let prime_section_arr = {};
var prime_json = [];
let total_amount_added = 0;
let multiselectRef = [];


class CreateReformulation extends Component {
  is_mounted = true;

  constructor(props) {
    super(props);
    const me = this;
    me.userInputArr = [];
    me.preAdddedOrganismArr = [];
    this.multiselectRef = React.createRef();
  }

  assignStateValue(sampleData) {
    totalConcentration = 0;
    totalAmmountAdded = 0;
    totalGain = 0;
    var tempArray = [];
    this.userInputArr = [];
    this.preAdddedOrganismArr = [];
    formulation_factor = 0;

    this.getMultiSelectTask(sampleData.sample_id);
    sampleData.organisms.filter((data) => {

      this.preAdddedOrganismArr.push(data.inventory.organism_barcode);

      totalConcentration = totalConcentration + data.final_concentration;
      totalAmmountAdded = totalAmmountAdded + data.amount_added;

      if (data.inventory != null) {
        let obj = {};
        obj = {
          cfu_total: data.amount_added * data.inventory.concentration,
        };
        tempArray.push(obj);
      }
    });
    // console.log(tempArray);
    const result = tempArray.reduce((acc, value) => ({
      cfu_total: parseFloat(acc.cfu_total) + parseFloat(value.cfu_total),
    }));

    totalGain = totalGain + result.cfu_total;
    var inventory_options = this.state.inventory_options;

    this.state.inventory_options.map((elem, i) => {
      sampleData.organisms.map((e, index) => {
        e.final_concentration = e.amount_added * e.inventory.concentration;
        e.total_cfu = e.amount_added * e.inventory.concentration;

        if (e.tags == undefined) {
          e.tags = []
        }
        if (e.inventory != null) {

          let organism_barcode = e.inventory.organism_barcode;
          type = organism_barcode.split("_")[0];
          if (elem.text === e.inventory.organism_barcode) {
            inventory_options = inventory_options.filter(function (sa) {
              return sa.text !== e.inventory.organism_barcode;
            });
            if (type === "FOR") {
              this.fetchData("for");
            } else if (type === "PRE" || type === "PRO") {
              this.fetchData("pre,pro");
            }
          }
        }
      });
    });



    this.setState({
      TotalCfu: totalGain,
      SelectedSampleId: sampleData.sample_id,
      TotalCfu: totalGain + profit,
      formulation_organism: sampleData.organisms,
      organisms: [],
      is_prime_added: false,
      organism_barcode:sampleData && sampleData.organism_barcode ? sampleData.organism_barcode : "",
      amount_added: sampleData && sampleData.amount_added ? sampleData.amount_added : "",
      concentration: sampleData && sampleData.concentration ? sampleData.concentration : 0,
      final_concentration: sampleData && sampleData.final_concentration
        ? sampleData.final_concentration : 0,
      inventory_id: sampleData && sampleData.inventory_id ? sampleData.inventory_id : 0,
      total_amount_added: totalAmmountAdded,
      total_final_concentration: totalConcentration,
      total_final_amount_added: this.state.formulation_factor * totalAmmountAdded,
      gain: totalGain / totalAmmountAdded,
      prime_filler_inventory_id: null,
      prime_filler_amount_added: 0,
      prime_filler_scrap_amount: 0,
      inventory_options,
    });
  }


  // STATE INFO
  getInitState = () => {
    return {
      is_popUp_disabled: false,
      popUp_description: "",
      warning: "",
      btn_disable: true,
      tags: [],
      SelectedSampleId: "",
      multiSelectData: [],
      organism: [],
      prime_filler_scrap_amount: 0,
      Check_formula_value: null,
      waiting_message: "",
      reformulationId: "",
      module_name_total_info: "",
      existingGain: 0,
      existingTotalAmount: 0,
      existingTotalFinalAmount: 0,
      check_insert: 0,
      check: 0,
      formulation_check: 1,
      TotalCfu: 0,
      gain: 0,
      calculationTop: 0,
      calculationCorrect: 0,
      payment_type: "Paid",
      is_saving_successful: false,
      is_saving_failed: false,
      email_sent: 0,
      is_waiting_for_response: false,
      is_message_visible: true,
      is_page_loading: true,
      response_message: "",
      form_error: "",
      product_type: "",
      //formula info state(s)
      sample_id: "",
      formula_id: "",
      date_created: new Date(),
      lot: 0,
      formulation_factor: 1,
      number_of_months: 3,
      sgNumber: "",
      //prime
      prime_filler_inventory_id: null,
      prime_flourish_amount_added: 0,
      prime_filler_amount_added: 0,
      prime_total_amount_added: 0,
      prime_capsule_size: 0,
      is_prime_added: false,

      //flourish
      flourish_capsule_size: 0,
      admin_notes: "",
      //organisms state(s)
      organisms: [],
      inventory_id: 0,
      createRefillpermission: 0,
      createRefillFormulation: 0,
      disabledPermission: "",
      refillAmountAdded: 0,
      refillInventory_id: 0,
      refillInventory_id0: 0,
      refillInventory_id1: 0,
      refillInventory_id2: 0,
      refillInventory_id3: 0,
      refillamountAdded: 0,
      //organism state(s)

      formulation_organism: "",
      organism_barcode: "",
      amount_added: "",
      concentration: 0,
      final_concentration: 0,

      scrap_amount: 0,
      final_amount_added: 0,
      total_cfu: 0,
      is_amount_added_exceeding_qty: false,
      is_prime_filler_amount_added_exceeding_qty: false,
      is_organism_already_added: false,
      is_prime_added_disabled:false,
      is_number_of_month_disabled:false,
      formula_type : "pro",
      //total info state(s)
      total_amount_added: 0,
      total_final_concentration: 0,
      total_final_amount_added: 0,
      inventory_options: [],
      inventory_options_prime: [],
      reqd_inventory_fields_json: [],
      converted_reqd_inventory_fields_json: [],
      form_errors: {
        sample_id: "",
        formula_id: "",
        number_of_months: "",
        prime_filler_inventory_id: "",
      },
      reformulation_notes: "",
    };
  };

  state = this.getInitState();

  change = (value) => {
    // when capsule size change assign new value
    this.setState({ flourish_capsule_size: value });

    var months = value === "powder" ? 3 : Number(this.state.number_of_months);

    formulation_factor = (months / 3).toFixed(3);
    this.setState({
      number_of_months: months,
      formulation_factor:formulation_factor,
    });

    if (this.state.is_prime_added == true) {
      this.setState({
        prime_capsule_size: value,
      });
    } else {
      this.setState({
        prime_capsule_size: "",
      });
    }
  };

  handlerProductType = (value,reformulation_request_source) => {
    if(reformulation_request_source === "Automatic Refill"){
      this.setState({
        product_type: value,
        number_of_months: 3,
        formulation_factor: 1,
      });
    }
         
  if (array_product_type.includes(value)) {
      this.setState({
        product_type: value,
        number_of_months: 6,
        formulation_factor: 2,
      });
    } else {
      this.setState({
        product_type: value,
        number_of_months: 3,
        formulation_factor: 1,
      });
    }

  var months_number = 0;
  var checkFloreBasic = value.includes('Flore Basic');
  
    
 var conditionCheck = false;

 if(product_type_six_twelve_validation.indexOf(value)> -1){      
      months_number = probiotic_product_type.indexOf(value) !== -1 ? six_twelve_month_wise_validation.probiotics : six_twelve_month_wise_validation.prebiotics;
      conditionCheck = true;
  }else if(validation_product_type.indexOf(value) !== -1 || (product_type_probiotics_validation.indexOf(value) !== -1)){
    months_number = (product_type_probiotics_validation.indexOf(value) !== -1)
    ?month_wise_validation.probiotics: month_wise_validation.prebiotics;
    conditionCheck = true;  

    // 3 month pre / probiotics validation
    if(value.includes('(3 months)')){
      months_number = product_type_3_month.indexOf(value)>-1?month_wise_validation.probiotics_3:
      month_wise_validation.prebiotics_3
      }

    value.includes('Flore Clinical Upgrade (2 months) / prebiotics') && (months_number = month_wise_validation.prebiotics_3)
    value.includes('Flore Clinical Upgrade (3 months) / probiotics') && (months_number = month_wise_validation.probiotics_3)
    value.includes('Flore Clinical Upgrade (4 months) / prebiotics') && (months_number = month_wise_validation.prebiotics)
    value.includes('Flore Clinical Upgrade (5 months) / probiotics') && (months_number = month_wise_validation.probiotics)
 }
 var probiotics_condition  = value.includes("probiotics")

 if(checkFloreBasic){
  probiotics_condition = checkFloreBasic;
  months_number = 3;
}

if(conditionCheck || checkFloreBasic){
 this.setState({
   is_prime_added:probiotics_condition,
   number_of_months:  months_number,
   is_prime_added_disabled:!probiotics_condition ,
   formulation_factor : probiotics_condition?(months_number/3).toFixed(3): (months_number*31).toFixed(3),
   flourish_capsule_size: probiotics_condition?this.state.flourish_capsule_size:"powder",
   is_number_of_month_disabled:probiotics_condition?false:true
 })
 
}


if(value == "Flore Clinical (3 months)")
{
  this.setState({
    number_of_months:  3,
    formulation_factor :1,
  })
}


 
};

  handlerPayementType = (value) => {
    this.setState({
      payment_type: value,
    });
  };

  handlerAssign = (sampleData) => {
    this.assignStateValue(sampleData);
  };

  changeStructureFunction = (value) => {
    if (this.state.organisms.length === 0) {
      this.fetchData("pre,pro,for");
    } else if (type === "PRE" || type === "PRO") {
      this.fetchData("pre,pro");
    } else {
      this.fetchData("for");
    }
    this.onInputChange(value, {
      name: this.state.organism_barcode,
      value: this.state.inventory_id,
    });
  };

  componentDidMount() {
    document.title = Config.formulations.title.createReformulation;
    let loginSucess = localStorage.getItem("login_success");
    if (loginSucess == 1) {
      this.getUserPermission();
    }
    this.is_mounted = true;
    this.fetchDataPrime();
    this.fetchData("pre,pro,for");
    
  }

  fetchDataPrime = () => {
    let inventory_options_prime = [];

    customPrint(
      Config.formulations.section_title.create,
      PRINT_CATEGORIES.REQUEST,
      baseURL + "/inventory/inventoryOptions"
    );

    // API call to get the inventory options
    axios
      .get(
        baseURL + "/inventory/inventoryOptions?type=pre,pro,prm",
        getHeaders()
      )
      .then((response) => {
        customPrint(
          Config.formulations.section_title.createRefill,
          PRINT_CATEGORIES.RESPONSE,
          response
        );

        const reqd_inventory_fields_json = response.data;
        const converted_reqd_inventory_fields_json =
          convertReqdInventoryOptionsJSON(reqd_inventory_fields_json);
        // Add organisms to inventory_options.
        prime_json = converted_reqd_inventory_fields_json;


        reqd_inventory_fields_json.forEach((organism) => {
          inventory_options_prime.push({
            key: organism.id,
            value: organism.id,
            active: true,
            text: organism.organism_barcode,
            icon: "dna",
            amount_added: organism.qty,
          });
        });

        if (this.is_mounted) {
          this.setState({
            reqd_inventory_fields_json,
            converted_reqd_inventory_fields_json,
            inventory_options_prime,
          });
          this.isPageLoading(false)
        }
      })
      .catch((error) => {
        alert("Fetching Inventory Options | Error: " + error);
        if (this.is_mounted) {
          this.isPageLoading(false)
        }
      });
  };

  fetchData = (type, userInputArr = []) => {
    let inventory_options = [];

    customPrint(
      Config.formulations.section_title.create,
      PRINT_CATEGORIES.REQUEST,
      baseURL + "/inventory/inventoryOptions"
    );

    // API call to get the inventory options
    axios
      .get(baseURL + `/inventory/inventoryOptions?type=${type}`, getHeaders())
      .then((response) => {
        customPrint(
          Config.formulations.section_title.createRefill,
          PRINT_CATEGORIES.RESPONSE,
          response
        );

        const reqd_inventory_fields_json = response.data;
        const converted_reqd_inventory_fields_json =
          convertReqdInventoryOptionsJSON(reqd_inventory_fields_json);
        // Add organisms to inventory_options.
        reqd_inventory_fields_json.forEach((organism) => {
          inventory_options.push({
            key: organism.id,
            value: organism.id,
            active: true,
            text: organism.organism_barcode,
            icon: "dna",
            amount_added: organism.qty,
          });
        });

        if (userInputArr && userInputArr.length) {
          inventory_options.map(({ text }, index) => {
            userInputArr.map((elem) => {
              if (text === elem) {
                inventory_options.splice(index, 1);
              }
            });
          });
        }

        if (this.is_mounted) {
          this.setState({
            reqd_inventory_fields_json,
            converted_reqd_inventory_fields_json,
            inventory_options,
          });
          // this.isPageLoading(false)
        }
      })
      .catch((error) => {
        alert("Fetching Inventory Options | Error: " + error);
        if (this.is_mounted) {
          this.isPageLoading(false)
        }
      });
  };

  fetchDataForLength = (type) => {
    let inventory_options = [];

    customPrint(
      Config.formulations.section_title.create,
      PRINT_CATEGORIES.REQUEST,
      baseURL + "/inventory/inventoryOptions"
    );

    // API call to get the inventory options
    axios
      .get(baseURL + `/inventory/inventoryOptions?type=${type}`, getHeaders())
      .then((response) => {
        customPrint(
          Config.formulations.section_title.createRefill,
          PRINT_CATEGORIES.RESPONSE,
          response
        );

        const reqd_inventory_fields_json = response.data;
        const converted_reqd_inventory_fields_json =
          convertReqdInventoryOptionsJSON(reqd_inventory_fields_json);
        // Add organisms to inventory_options.
        reqd_inventory_fields_json.forEach((organism) => {
          inventory_options.push({
            key: organism.id,
            value: organism.id,
            active: true,
            text: organism.organism_barcode,
            icon: "dna",
            amount_added: organism.qty,
          });
        });
        if (this.is_mounted) {
          this.setState({
            reqd_inventory_fields_json,
            converted_reqd_inventory_fields_json,
            inventory_options,
          });
          // this.isPageLoading(false)
        }
      })
      .catch((error) => {
        alert("Fetching Inventory Options | Error: " + error);
        if (this.is_mounted) {
          this.isPageLoading(false)
        }
      });
  };

  changePrimeAddition = () => {
    let is_prime_added = this.state.is_prime_added === true ? false : true;

    // Changes the 'prime_filler_inventory_id' to null
    // so fetching the prime data, usage of prime can be detected.
    let form_errors = { ...this.state.form_errors };
    if (
      this.state.is_prime_added == false &&
      this.state.prime_filler_inventory_id == null
    ) {
      form_errors.prime_filler_inventory_id = "Please select an organism";
      this.setState({ form_errors });
    } else {
      form_errors.prime_filler_inventory_id = "";
      this.setState({ form_errors });
    }

    let prime_filler_inventory_id = is_prime_added
      ? this.state.prime_filler_inventory_id
      : null;
    let prime_flourish_amount_added = is_prime_added ? 1.2 : 0;
    let prime_filler_amount_added = is_prime_added ? 4.8 : 0;
    let prime_total_amount_added = is_prime_added ? 6 : 0;
    let prime_filler_scrap_amount = 0;
    this.setState({
      is_prime_added,
      prime_filler_inventory_id,
      prime_filler_scrap_amount,
      prime_flourish_amount_added,
      prime_filler_amount_added,
      prime_total_amount_added,
    });
  };

  onInputChange = (e, { name, value }) => {
    let form_errors = { ...this.state.form_errors };

    switch (name) {
      // Validation check.
      // Changes the 'prime_total_amount_added' based on the change in 'prime_flourish_amount_added'.

      case "prime_filler_inventory_id": {
        let form_errors = { ...this.state.form_errors };

        prime_section_arr.id = value;
        form_errors.prime_filler_inventory_id = "";
        let qty = prime_json[value].qty;
        let scrap_factor = getScrap(prime_json, value);

        if (value != null) {
          var demo_qty = qty;
          let scrap_amount = (Number(getScrapAmount(scrap_factor, value))).toFixed(2);
          // quantity

          //   add florish section value
          let florish_section_value = 0;
          let florish_scrap_amount = 0;

          this.state.organisms &&
            this.state.organisms.map((data) => {
              florish_section_value =
                data.inventory_id === value
                  ? Number(data.final_amount_added)
                  : 0;

              florish_scrap_amount =
                data.inventory_id === value
                  ? Number(data.scrap_amount)
                  : 0;

              qty = qty - (Number(florish_section_value) + Number(florish_scrap_amount));
            });

          florish_section_value += Number(florish_scrap_amount);
          var total_amount =
            Number(this.state.prime_filler_amount_added) +
            Number(scrap_amount) +
            Number(florish_section_value);

          if (total_amount > qty && total_amount > demo_qty) {
            demoQty = qty;
            scrap_amount = (Number(getScrapAmount(scrap_factor, qty))).toFixed(2);

            qty -= scrap_amount;

            this.setState({
              is_prime_filler_amount_added_exceeding_qty: true,
              check: demoQty
            });
            this.setState({
              form_errors,
              prime_filler_amount_added: (qty).toFixed(2),
              prime_filler_inventory_id: value,
              prime_filler_scrap_amount: scrap_amount,
              prime_total_amount_added: this.state.prime_flourish_amount_added + qty,
            });

          }
          if (this.state.inventory_id !== "" && this.state.inventory_id == this.state.prime_filler_inventory_id) {
            this.setState({ amount_added: 0 });
          }
        }
        else {
          this.setState({
            form_errors,
            prime_filler_amount_added: this.state.prime_filler_amount_added,
            prime_filler_inventory_id: value,
            prime_filler_scrap_amount: (Number(getScrapAmount(scrap_factor, this.state.prime_filler_amount_added))).toFixed(2),
            prime_total_amount_added: this.state.prime_flourish_amount_added + this.state.prime_filler_amount_added,
          });
        }

        if (this.state.inventory_id !== "" && this.state.inventory_id == value) {
          this.setState({ amount_added: 0 });
        }
        this.setState({
          form_errors
        })

        break;
      }
      case "prime_flourish_amount_added": {
        digitsAfterPrimeFlourish();
        this.setState({
          prime_total_amount_added: getPrimeTotalAmountAdded(
            value,
            this.state.prime_filler_amount_added
          ),
        });
        break;
      }


      case "prime_filler_amount_added": {
        //if the prime filler organism is not added yet, return.
        prime_filler_amount_added();
        if (this.state.inventory_id !== "" && this.state.inventory_id == this.state.prime_filler_inventory_id) {
          this.setState({ amount_added: 0 });
        }
        if (this.state.prime_filler_inventory_id === null) {
          alert("Please enter Prime Filler Organism first");
          return;
        }
        if (
          prime_json[this.state.prime_filler_inventory_id] !== undefined &&
          this.state.prime_filler_inventory_id !== undefined
        ) {
          // quantity
          let qty = prime_json[this.state.prime_filler_inventory_id].qty;
          var demo_qty = qty;

          //   count scrap amount of prime section
          let scrap_factor = getScrap(prime_json, this.state.prime_filler_inventory_id);
          let scrap_amount = (Number(getScrapAmount(scrap_factor, value))).toFixed(2);


          //   add florish section value
          let florish_section_value = 0;
          let florish_scrap_amount = 0;

          let formulation_organism_florish_section_value = 0;
          let formulation_organism_florish_scrap_amount = 0;

          this.state.organisms &&
            this.state.organisms.map((data) => {
              florish_section_value =
                data.inventory_id === this.state.prime_filler_inventory_id ? Number(data.final_amount_added) : 0;

              florish_scrap_amount = data.inventory_id === this.state.prime_filler_inventory_id ? Number(data.scrap_amount) : 0;

              qty = qty - (Number(florish_section_value) + Number(florish_scrap_amount));

            });



          this.state.formulation_organism &&
            this.state.formulation_organism.map((data) => {
              formulation_organism_florish_section_value = data.inventory_id === this.state.prime_filler_inventory_id ? Number(data.amount_added) : 0;
              formulation_organism_florish_scrap_amount = data.inventory_id === this.state.prime_filler_inventory_id
                ? Number(data.scrap_amount)
                : 0;
              qty = qty - (Number(formulation_organism_florish_section_value) + Number(formulation_organism_florish_scrap_amount));
            });


          florish_section_value += florish_scrap_amount;
          formulation_organism_florish_section_value += Number(formulation_organism_florish_scrap_amount);

          if (qty > 0) {
            var total_amount =
              Number(value) +
              Number(scrap_amount) +
              Number(florish_section_value) +
              Number(formulation_organism_florish_section_value);

            if (total_amount > qty && total_amount > demo_qty) {
              /*
              Note:-  when user input excided then pop show and
                          then florish amount deduct and after 
                          that find the scrap amount
                          */
              var demoQty = qty;
              scrap_amount = (Number(getScrapAmount(scrap_factor, qty))).toFixed(2);
              qty -= scrap_amount;

              this.setState({ is_prime_filler_amount_added_exceeding_qty: true, check: demoQty });
              value = (qty).toFixed(2);
            }

            if (this.state.inventory_id !== "" && this.state.inventory_id == this.state.prime_filler_inventory_id) {
              this.setState({ amount_added: 0 });
            }

            this.setState({
              prime_total_amount_added: getPrimeTotalAmountAdded(
                this.state.prime_flourish_amount_added,
                value
              ),
              prime_filler_scrap_amount: scrap_amount,
            });
          }
          else {
            this.setState({ is_prime_filler_amount_added_exceeding_qty: true, check: "0.00" });
            value = "0.00";
          }

        } else {
          alert("Error in Prime Filler Inventory id");
        }

        break;
      }

      // Handles the change in Number of Months.
      case "number_of_months": {
        if (!isNaN(value)) {
          form_errors.number_of_months =
            value > 0 ? "" : "Number of Months can not be negative";
            formulation_factor = 0;
             
          if(validation_product_type.indexOf(this.state.product_type) !== -1){
            formulation_factor = getFormulationFactorForClinicalFormula(value);
          }
          else{
            formulation_factor = getFormulationFactor(value);
          }

          
          let final_amount_added = getFinalAmountAdded(
            formulation_factor,
            this.state.amount_added
          );

          let total_final_amount_added = getTotalFinalAmountAdded(
            formulation_factor,
            this.state.total_amount_added
          );

          let total_cfu = getTotalCFU(
            this.state.concentration,
            final_amount_added
          );

          // change all prev organism records:
          let organisms = [...this.state.organisms];

          organisms.forEach((organism) => {
            let new_final_amount_added = getFinalAmountAdded(
              formulation_factor,
              organism.amount_added
            );

            let new_total_cfu = getTotalCFU(
              organism.concentration,
              new_final_amount_added
            );

            organism.final_amount_added = new_final_amount_added;
            organism.total_cfu = new_total_cfu;
          });

          this.setState({
            organisms,
            total_final_amount_added,
            formulation_factor,
            final_amount_added,
            total_cfu,
            form_errors,
          });
        }
        break;
      }
      // Handles the change in Organism.
      case "inventory_id": {
        this.state.inventory_id = value;
        let res;
        this.state.inventory_options.map((result) => {
          if (result.key == 0) {
            if (result.key === this.state.inventory_id) {
              res = 0;
            } else if (
              result.key !== this.state.inventory_id &&
              result.key == 0 &&
              result.key === value
            ) {
              res = 0;
            } else {
              res = 1;
            }
          }
        });
        if (res !== 0) {
          var x =
            this.state.converted_reqd_inventory_fields_json[value]
              .concentration;
          let concentration = x !== undefined ? x : 0;

          let scrap_factor =
            this.state.converted_reqd_inventory_fields_json[value].scrap_factor;

          let scrap_amount = getScrapAmount(
            scrap_factor,
            this.state.amount_added
          );

          let total_cfu = getTotalCFU(
            concentration,
            this.state.final_amount_added
          );

          let final_concentration = getFinalConcentration(
            concentration,
            this.state.amount_added,
            this.state.total_amount_added
          );

          let total_final_concentration = getTotalFinalConcentration(
            this.state.total_final_concentration,
            final_concentration,
            this.state.final_concentration
          );

          this.setState({
            concentration,
            scrap_amount,
            total_cfu,
            final_concentration,
            total_final_concentration,
          });
        }
        break;
      }

      // Handles the change in Organism amount_added.
      case "amount_added": {
        digitsAfterPointOrganism();
        // If the organism is not added yet, returns an alert.

        if (this.state.inventory_id === 0) { alert("Please enter the Organism"); return; }

        this.setState({ Check_formula_value: value });

        let qty = this.state.converted_reqd_inventory_fields_json[this.state.inventory_id].qty;
        var demo_qty = qty;
        /* Task Improve inventory management - part 2 Start */

        let prime_Section_value = 0;
        let prime_section_scrap_amount = 0;
        if (
          (Object.keys(prime_section_arr).length !== 0 &&
            this.state.inventory_id === prime_section_arr["id"]) &&
          this.state.is_prime_added
        ) {
          prime_Section_value =
            this.state.prime_filler_amount_added !== ""
              ? Number(this.state.prime_filler_amount_added)
              : 0;
          prime_section_scrap_amount =
            this.state.prime_filler_amount_added !== ""
              ? Number(this.state.prime_filler_scrap_amount)
              : 0;
          let x = value;
          if (x + Number(prime_Section_value) + Number(prime_section_scrap_amount) < qty) {
            qty = qty - (Number(prime_Section_value) + Number(prime_section_scrap_amount)).toFixed(2);
            if (x <= qty) {
              qty = x;
            }
          } else {
            qty -= ((Number(prime_Section_value) + Number(prime_section_scrap_amount))).toFixed(2);
          }
        }
        prime_Section_value += prime_section_scrap_amount;
        /* Task:- Improve inventory management - part 2 End */
        var formulation_factor_multiply = Number(value * this.state.formulation_factor);

        let scrap_factor = getScrap(this.state.converted_reqd_inventory_fields_json, this.state.inventory_id);
        let scrap_amount = (Number(getScrapAmount(scrap_factor, formulation_factor_multiply))).toFixed(2);


        var x = formulation_factor_multiply + Number(scrap_amount) + Number(prime_Section_value);
        var formulation_organism = [...this.state.formulation_organism];
        var preadded_organism_value = 0;
        formulation_organism && formulation_organism.map((item) => {
          if (item.inventory.id == this.state.inventory_id) {
            preadded_organism_value = Number(item.final_amount_added);
            preadded_organism_value += Number(item.scrap_amount);
          }
        });
        x += preadded_organism_value;

        if (x > qty && x > demo_qty) {
          /* Note:-  when user input excided then pop show and then prime section amount deduct and after  that find the scrap amount */
          var error_qty = 0;
          qty -= preadded_organism_value;
          if (qty > 0) {
            qty = qty / this.state.formulation_factor;

            error_qty = qty;

            scrap_amount = (Number(getScrapAmount(scrap_factor, qty))).toFixed(2);
            qty -= scrap_amount;
            scrap_amount = scrap_amount * this.state.formulation_factor;

          }

          value = (Number(qty)).toFixed(2);
          this.setState({
            is_amount_added_exceeding_qty: true,
            check: (Number(error_qty)).toFixed(3),
            check_insert: value,
          });
        }
        // final_concentration
        let final_concentration = getFinalConcentration(
          this.state.concentration,
          value,
          total_amount_added
        );

        //all dependencies get changed
        let final_amount_added = getFinalAmountAdded(
          this.state.formulation_check,
          value
        );
        this.setState({
          amount_added: value,
          scrap_amount,
          final_concentration,
          final_amount_added,
        });
        break;
      }
      default:
    }

    //Main Condition

    this.setState({ [name]: value });
  };

  /**
   * Handles the outside click for the Less Quantity Alert Modal.
   */
  onClickOutside = () => {
    this.setState({
      is_amount_added_exceeding_qty: false,
      is_prime_filler_amount_added_exceedifng_qty: false,
      is_organism_already_added: false,
    });
  };
  getUserPermission = () => {
    let Role = getUserRole();
    let login = localStorage.getItem("login_success");
    if (login == 1) {
      axios
        .get(baseURL + "/users/PermissionDetails", getHeaders())
        .then((response) => {
          let permissionData = null;

          permissionData = simplifyModulePermissionData(response.data);
          permissionData = permissionData.filter((data) => {
            if (data.role_name === Role) {
              return data;
            }
          });

          let createRefillFormulation = 0;
          let disabledPermission;
          let createRefillpermission = permissionData.forEach((result) => {
            if (
              result.module_name === "Create Reformulation" &&
              result.edit_permission === 1
            ) {
              createRefillFormulation = 1;
              disabledPermission = false;
            }
          });

          if (createRefillFormulation == 0) {
            createRefillpermission = 0;
            disabledPermission = true;
          } else {
            createRefillpermission = 1;
          }
          this.setState({
            module_name_total_info: "Create Reformulation",
          });
          this.setState({
            disabledPermission,
          });
        })
        .catch((error) => {
          alert("Fetching Display Grid | Error: ", error);
        });
    }
  };
  // Handles submit click.
  onSubmitClick = () => {
    // Validation check.
    if (isFormValid(this.state)) {
      if (this.state.sample_id == "") {
        this.state.sample_id = selectedSample
          ? selectedSample
          : this.state.sample_id;
      }
      if (this.state.formula_id == "") {
        this.state.formula_id = selectedFormula
          ? selectedFormula
          : this.state.formula_id;
      }
      //check before sumitting irf inventory has that taht quantitiy or not
      let dbAmount;
      this.state.inventory_options.map((result) => {
        if (result.key === this.state.inventory_id) {
          if (result.amount_added == null) {
            dbAmount = 0;
          } else {
            dbAmount = result.amount_added;
          }
        }
      });

      if (dbAmount < this.state.amount_added) {
        alert(
          "Not enough quantity in inventory.So please choose less than or equal to " +
          dbAmount
        );
        return false;
      }

      this.setState({
        is_saving_successful: false,
        is_saving_failed: false,
      });

      let res = _.pick(this.state, [
        "sample_id",
        "formula_id",
        "product_type",
        "date_created",
        "number_of_months",
        "formulation_factor",
        "admin_notes",
        "prime_filler_scrap_amount",
        "prime_filler_inventory_id",
        "prime_flourish_amount_added",
        "prime_filler_amount_added",
        "prime_total_amount_added",
        "email_sent",
        "flourish_capsule_size",
        "organisms",
        "payment_type",
        "total_amount_added",
        "total_final_concentration",
        "total_final_amount_added",
        "reformulation_notes",
        "formula_type"
      ]);

      

      res.product_type = Product_Type[res.product_type];
       
      res.user_id = getSessionStoredJsonItem("user").user_id;
      res.date_created = getDateString(this.state.date_created);
      res.total_final_concentration = this.state.gain;
      res.prime_capsule_size = this.state.is_prime_added
        ? this.state.prime_capsule_size
        : "";

      this.setState({ is_waiting_for_response: true });

      if (Clinical_Prebiotic.includes(res.product_type)) {
        this.setState({formula_type:"pre"});
        res.formula_type = "pre";
      }

      if (this.state.formulation_organism.length > 0) {
        this.state.formulation_organism.forEach((organismData) => {
          organismData.final_amount_added = getFinalAmountAdded(
            this.state.formulation_factor,
            organismData.amount_added
          );
          if (organismData.inventory != null) {
            res.organisms.push({
              inventory_id: organismData.inventory_id,
              organism_barcode: organismData.inventory.organism_barcode,
              amount_added: organismData.amount_added,
              concentration: organismData.inventory.concentration,
              final_concentration: organismData.amount_added * organismData.inventory.concentration,
              scrap_amount: organismData.final_amount_added * organismData.inventory.scrap_factor,
              tags: organismData.tags,
              // nonSelectedTag: [],
              final_amount_added: organismData.final_amount_added,
              total_cfu:
                organismData.final_amount_added *
                organismData.inventory.concentration,
            });
          }
        });
      }

      let return_flag = false;
      res.organisms.forEach((item) => {

        if (item.amount_added <= 0) {
          return_flag = true;
          alert(`Organisms ${item.organism_barcode} Amount Added Value Can't be blank`);
          return;
        }
        var x = [];
        item.nonSelectedTag = [];
        if (item.tags !== undefined) {
          item && item.tags.map((a) => x.push({ name: a.label, id: a.id }));
          item.tags = x;
        }
      });
      if (return_flag !== false) {
        this.isPageLoading(false)
        return;
      }
  
      axios
        .post(
          baseURL +
          "/formulations/CreateReformulation/" +
          this.state.reformulationId,
          res,
          getHeaders()
        )
        .then((response) => {
          customPrint(
            Config.formulations.section_title.create,
            PRINT_CATEGORIES.RESPONSE,
            response
          );
          focusFirstElement();
          window.scrollTo(0, 0);
          if (response.data.success === true) {
            setInterval(() => {
              window.location.reload(false);
            }, 1000);
          }
          let init_state = this.getInitState();
          init_state["inventory_options"] = this.state.inventory_options;
          init_state["converted_reqd_inventory_fields_json"] =
            this.state.converted_reqd_inventory_fields_json;
          init_state["reqd_inventory_fields_json"] =
            this.state.reqd_inventory_fields_json;

          init_state["is_saving_successful"] = response.data.success;
          init_state["is_saving_failed"] = !response.data.success;
          init_state["response_message"] = response.data.message;
          init_state["is_page_loading"] = false;
          init_state["is_waiting_for_response"] = false;
          init_state["is_message_visible"] = true;
          init_state["flourish_capsule_size"] =
            this.state.flourish_capsule_size;
          init_state["prime_capsule_size"] = this.state.prime_capsule_size;
          init_state["product_type"] = this.state.product_type;
          this.setState(init_state);


        })
        .catch((error) => {
          alert("Create Refill | Error: " + error);
          this.isPageLoading(false)
        });

      this.isPageLoading(false)
    } else {
      alert(
        "Please remove the errors shown in red font prior to submiting the form."
      );
    }
  };

  // Handles cancel click.
  onCancelClick = () => {
    let init_state = this.getInitState();
    init_state["is_page_loading"] = false;
    init_state["inventory_options"] = this.state.inventory_options;
    init_state["converted_reqd_inventory_fields_json"] =
      this.state.converted_reqd_inventory_fields_json;
    init_state["reqd_inventory_fields_json"] =
      this.state.reqd_inventory_fields_json;

    this.setState(init_state);
  };

  // Hides the SUCCESS/FAILURE message
  hideMessage = () => {
    this.setState({ is_message_visible: false });
  };

  // Handles the date change.
  onDateChange = (name, date) => {
    if (name === Config.formulations.date_created) {
      this.setState({ date_created: date });
    } else if (name === Config.formulations.date_made) {
      this.setState({ date_made: date });
    } else {
    }
  };

  /**
   * Handles the delete for added organism.
   *
   * @param {String} organism_barcode
   */
  onDeleteOrganismClick = (organism_barcode) => {


    if (document.activeElement.tagName === "INPUT") {
      return;
    }
    let organisms = [...this.state.organisms];
    let formulation_organism = [...this.state.formulation_organism];

    organisms.splice(
      organisms.findIndex((a) => a.organism_barcode === organism_barcode),
      1
    );

    var total_amount_added = 0;
    var total_cfu = 0;

    organisms && organisms.map((items) => {
      total_amount_added += Number(items.amount_added);
      total_cfu += Number(items.total_cfu);
    });

    formulation_organism && formulation_organism.map((items) => {
      total_amount_added += Number(items.amount_added);
      total_cfu += Number(items.total_cfu);
    });
    if (formulation_organism.length === 0 && organisms.length === 0) {
      type = "";
    }
    this.preAdddedOrganismArr = this.preAdddedOrganismArr.filter((item) => item !== organism_barcode);
    this.userInputArr = this.userInputArr.filter((item) => item !== organism_barcode);

    if (organisms.length === 0 && formulation_organism.length === 0) {
      this.userInputArr = [];
      this.fetchDataForLength("pre,pro,for");
    } else if (type === "FOR") {
      this.fetchData("for");
    } else {
      this.fetchData("pre,pro");
    }
    var factor = formulation_factor !== 0 ? formulation_factor : this.state.formulation_factor;



    this.setState({
      total_final_concentration: total_cfu,
      total_amount_added: total_amount_added,
      gain: isNaN(total_cfu / total_amount_added)
        ? 0
        : total_cfu / total_amount_added,
      total_final_amount_added: factor * total_amount_added,
      formulation_factor: factor,
      organisms,
      formulation_organism
    });

  };

  onDeleteRefillOrganismClick = (
    organism_barcode,
    result,
    inventory_id,
    amount,
    final_concentration,
    zero
  ) => {



    var formulation_organism = this.state.formulation_organism;
    var organisms = this.state.organisms;

    formulation_organism.splice(formulation_organism.findIndex((a) => a.inventory.organism_barcode === organism_barcode), 1);

    var total_amount_added = 0;
    var total_cfu = 0;

    formulation_organism && formulation_organism.map((items) => {
      total_amount_added += Number(items.amount_added);
      total_cfu += Number(items.total_cfu);
    });
    organisms && organisms.map((items) => {
      total_amount_added += Number(items.amount_added);
      total_cfu += Number(items.total_cfu);
    });

    var factor = formulation_factor !== 0 ? formulation_factor : this.state.formulation_factor;
    this.setState({
      total_final_concentration: total_cfu,
      total_amount_added: total_amount_added,
      gain: isNaN(total_cfu / total_amount_added)
        ? 0
        : total_cfu / total_amount_added,
      total_final_amount_added: factor * total_amount_added,
      formulation_factor: factor,
      formulation_organism,
      organisms
    });

    for (var i = 0; i < this.state.inventory_options.length; i++) {
      if (this.state.inventory_options[i]["key"] == inventory_id) {
        this.state.inventory_options[i].active = true;
      }
    }

    this.setState({
      inventory_options: this.state.inventory_options,
    });
    let organismId = result;
    let index = this.state.formulation_organism.findIndex(
      (x) => x.id == organismId
    );

    this.preAdddedOrganismArr = this.preAdddedOrganismArr.filter((barocde) => barocde !== organism_barcode);

    if (index > -1) {
      this.state.formulation_organism.splice(index, 1);
    }
    if (this.state.formulation_organism.length === 0 && this.state.organisms.length === 0) {
      type = "";
    }

    alert("Successfully deleted the organism(s)");
    if (this.state.formulation_organism.length === 0 && this.state.organisms.length === 0) {
      this.fetchData("pre,pro,for");
    } else if (type === "FOR") {
      this.fetchData("for");
    } else {
      this.fetchData("pre,pro");
    }
  };

  /**
   * Handles addition to the organism.
   */
  onAddOrganismClick = () => {
    if (
      this.state.amount_added == 0 ||
      this.state.inventory_id === 0 ||
      this.state.amount_added === ""
    ) {
      alert("Please select both the Ingredient Barcode and the Amount added!");
      return;
    }
    this.setState({ Check_formula_value: null });

    let organisms = [...this.state.organisms];

    var total_final_concentration = 0;
    var total_amount_added = 0;
    var total_cfu = 0;

    // changes all of previous organisms' "final concentration"
    organisms.forEach((organism) => {
      let final_concentration = getFinalConcentration(
        organism.concentration,
        organism.amount_added,
        this.state.total_amount_added
      );
      organism.final_concentration = final_concentration;
      total_final_concentration += final_concentration;
    });

    // Adds the new 'organism'.
    organisms.push({
      inventory_id: this.state.inventory_id,
      organism_barcode: this.state.organism_barcode,
      amount_added: this.state.amount_added,
      concentration: this.state.concentration,
      final_concentration: this.state.final_concentration,
      scrap_amount: this.state.scrap_amount,
      tags: (this.state.tags.length > 0) ? this.state.tags : [],
      nonSelectedTag: this.state.multiSelectData,
      final_amount_added:
        this.state.formulation_factor * this.state.final_amount_added,
      total_cfu:
        this.state.amount_added * this.state.concentration <= 0
          ? 0
          : this.state.amount_added * this.state.concentration,
      total_cfu_api: this.state.amount_added * this.state.concentration <= 0
        ? 0
        : this.state.amount_added * this.state.concentration,

    });
    organisms && organisms.map((items) => {
      total_amount_added += Number(items.amount_added);
      total_cfu += Number(items.total_cfu);
    });


    var formulation_organism = this.state.formulation_organism;

    formulation_organism && formulation_organism.map((items) => {
      total_amount_added += Number(items.amount_added);
      total_cfu += Number(items.total_cfu);
    });

    var factor = formulation_factor !== 0 ? formulation_factor : this.state.formulation_factor;
    this.setState({
      organisms,
      total_final_concentration: total_cfu,
      total_amount_added: total_amount_added,
      gain: isNaN(total_cfu / total_amount_added)
        ? 0
        : total_cfu / total_amount_added,
      total_final_amount_added: factor * total_amount_added,
      formulation_factor: factor,
      Check_formula_value: null
    });


    // Removes the new organism from the inventory_options list.

    for (var i = 0; i < this.state.inventory_options.length; i++) {
      if (this.state.inventory_options[i]["key"] == this.state.inventory_id) {
        this.state.inventory_options[i]["active"] = false;
      }
    }
    let { organism_barcode } = this.state;
    this.userInputArr = [...this.userInputArr, organism_barcode];
    this.preAdddedOrganismArr = [...this.preAdddedOrganismArr, organism_barcode];

    type = this.state.organism_barcode.split("_")[0];
    if (type === "FOR") {
      this.fetchData("for", this.userInputArr);
    } else if (type === "PRE" || type === "PRO") {
      this.fetchData("pre,pro", this.userInputArr);
    }
    // clear when tag added in dropdown
    this.multiselectRef.current.clearValue();

    // Resets the organism state values.
    this.setState(this.resetOrganismState());
  };

  resetOrganismState = () => {
    return {
      organism_barcode: "",
      inventory_id: 0,
      amount_added: "",
      concentration: 0,
      final_concentration: 0,
      scrap_amount: 0,
      final_amount_added: 0,
      total_cfu: 0,
    };
  };

  flore_probiotics = (value) => {
    let powder = "powder"
    if(validation_product_type.indexOf(this.state.product_type) == -1){
      if (value === "powder") {
        this.setState({
          flourish_capsule_size: powder,
          prime_capsule_size: powder
        })
      }
      else {
        this.setState({
          flourish_capsule_size: 0,
          prime_capsule_size: 0
        })
      }
    }
    
  }
  reformulationId = (value) => {
    console.log(value);
    this.setState({
      reformulationId: value,
    });
  };

  reformulation_notes = (e) => {
    this.setState({ reformulation_notes: e.target.value });
  };
  changeLoadingStateValue = (waiting_value, msg) => {
    this.setState({
      is_waiting_for_response: waiting_value,
      waiting_message: msg,
    });
  };
  // set pop_up value
  pop_up_open = () => {
    this.setState({ is_popUp_disabled: false });
  };

  Modal_return_value = (value, message) => {
    if (value) {
      setTimeout(() => {
        if (
          total_amount_added !== Number(this.state.total_final_amount_added) &&
          warning === ""
        ) {
          warning = "quantitiy_warning";
          popUp_description =
            "Warning! The Total Amount Added  for the formula you're about to create is different from what is expected! Do you still want to proceed?";

          this.setState({ is_popUp_disabled: true });
          return;
        } else {
          this.onSubmitClick(submit_event);
        }
        return;
      }, 1000);
    } else {
      warning = "";
      return;
    }
  };


  confirm_value = (event) => {

    submit_event = event;

    if (
      this.state.prime_filler_inventory_id == null &&
      this.state.is_prime_added === true
    ) {
      alert("Prime is added, please select a filler!");
      return false;
    }
    if (
      (this.state.prime_filler_inventory_id == null &&
        this.state.is_prime_added == true) ||
      (this.state.prime_filler_inventory_id !== null &&
        (this.state.prime_filler_amount_added === "" ||
          Number(this.state.prime_filler_amount_added) === 0))
    ) {

      let x =
        this.state.prime_filler_amount_added === "" ||
          Number(this.state.prime_filler_amount_added) === 0
          ? "Prime Filler Amount Added Can't be blank!"
          : "Prime is added, please select a filler!";
      alert(x);
      return;
    }

    if (this.state.is_prime_added == true && this.state.prime_filler_inventory_id == " ") {
      alert("please Insert Inventory id ");
      return;
    }
    if (
      this.state.organisms.length == 0 &&
      this.state.formulation_organism.length == 0
    ) {
      alert(
        "Please select at least one organism barcode from flourish section before creating formula "
      );
      return;
    }

    if (
      this.state.Check_formula_value !== null &&
      this.state.Check_formula_value !== ""
    ) {
      alert("Please Insert Ingredient");
      return;
    }

    let is_valid = true;

    if (this.state.formulation_organism) {
      this.state.formulation_organism.forEach((details) => {
        if (
          this.state.inventory_options.find(
            (element) =>
              element.key == details.inventory.id &&
              element.amount_added < details.amount_added
          )
        ) {
          is_valid = false;
        }
        if (
          !this.state.inventory_options.find(
            (element) => element.key == details.inventory.id
          )
        ) {
          is_valid = false;
        }
      });
      if (!is_valid) {
        alert(
          "At least one ingredient barcode is not available anymore. Please remove it or replace it with another ingredient barcode before clicking on Create Reformulation button."
        );
        return false;
      }
    }

    if (this.state.amount_added === 0 && this.state.inventory_id !== 0) {
      this.setState({ amount_added: 0 });
      alert(
        "The last Item has not been added to the Ingredients. Hence, the amount_added is set to 0. However, if you wish to add it to the Organisms, please click on insert before clicking on Submit."
      );
      return;
    }
    if(validation_product_type.indexOf(this.state.product_type) == -1
    && product_type_probiotics_validation.indexOf(this.state.product_type) == -1 ){
    

    if ([3, 6].indexOf(Number(this.state.number_of_months)) === -1) {
      popUp_description =
        "Warning! The number of months for the formula you're about to create is not 3 or 6 months? Do you still want to proceed?";

      this.setState({
        is_popUp_disabled: true,
        popUp_description: "this is working ",
      });
      return;
    } else {
      if (total_amount_added !== Number(this.state.total_final_amount_added)) {
        warning = "quantitiy_warning";
        popUp_description =
          "Warning! The Total Amount Added  for the formula you're about to create is different from what is expected! Do you still want to proceed?";

        this.setState({
          is_popUp_disabled: true,
          popUp_description: "this is workings",
        });
        return;
      } else {
        this.onSubmitClick(event);
      }
    }
    }else{
      this.onSubmitClick(event);
    }
  };

  onChangeOf_organism = (value, inventory_id, barcode) => {
    if (inventory_id == undefined) {
      alert("Please enter the Organism");
      return;
    }

    var inventory_info = this.state.converted_reqd_inventory_fields_json[inventory_id];

    var qty = inventory_info.qty;
    var demo_qty = qty;
    let scrap_factor = inventory_info.scrap_factor;

    let scrap_amount = getScrapAmount(scrap_factor, value);

    let prime_Section_value = 0;
    let prime_section_scrap_amount = 0;

    if (
      (Object.keys(prime_section_arr).length !== 0 &&
        inventory_id === prime_section_arr["id"]) &&
      this.state.is_prime_added
    ) {
      prime_Section_value =
        this.state.prime_filler_amount_added !== ""
          ? Number(this.state.prime_filler_amount_added)
          : 0;
      prime_section_scrap_amount =
        this.state.prime_filler_amount_added !== ""
          ? Number(this.state.prime_filler_scrap_amount)
          : 0;
      let x = value;
      if (x + Number(prime_Section_value) + Number(prime_section_scrap_amount) < qty) {
        qty = qty - (Number(prime_Section_value) + Number(prime_section_scrap_amount)).toFixed(2);
        if (x <= qty) {
          qty = x;
        }
      } else {
        qty -= ((Number(prime_Section_value) + Number(prime_section_scrap_amount))).toFixed(2);
      }
    }
    prime_Section_value += prime_section_scrap_amount;


    this.state.formulation_organism && this.state.formulation_organism.map((item) => {
      if (item.inventory_id == value.inventory_id) {
        prime_Section_value += item.final_amount_added;
        prime_Section_value += item.scrap_amount;
      }
    });

    this.state.organisms && this.state.organisms.map((item) => {
      if (item.inventory_id == value.inventory_id) {
        prime_Section_value += item.final_amount_added;
        prime_Section_value += item.scrap_amount;
      }
    })
    var compare_value = value * this.state.formulation_factor;

    compare_value += Number(scrap_amount) + Number(prime_Section_value);


    if (compare_value > qty && compare_value > demo_qty) {
      var demoQty;
      qty = qty / this.state.formulation_factor;
      demoQty = qty;
      scrap_amount = Number(getScrapAmount(scrap_factor, qty));
      qty -= scrap_amount;
      value = (Number(qty)).toFixed(2);
      this.setState({
        is_amount_added_exceeding_qty: true,
        check: demoQty
      })

    }

    var organisms = this.state.organisms;
    var formulation_organism = this.state.formulation_organism;

    var total_amount_added = 0;
    var total_cfu = 0;

    organisms && organisms.map((item) => {
      if (item.organism_barcode == barcode) {
        item.amount_added = value;
        item.final_amount_added = value * this.state.formulation_factor;
        item.final_concentration = value * item.concentration;
        item.total_cfu = value * item.concentration;
        item.scrap_amount = scrap_amount;
      }
      total_amount_added += Number(item.amount_added);
      total_cfu += Number(item.total_cfu);
    });

console.log(organisms);
    
    formulation_organism && formulation_organism.map((item) => {
      if (item.inventory.organism_barcode == barcode) {
        item.amount_added = value;
        item.final_amount_added = value * this.state.formulation_factor;
        item.final_concentration = item.amount_added * item.inventory.concentration;
        item.scrap_amount = scrap_amount;
        item.total_cfu = item.final_concentration;
      }
      total_amount_added += Number(item.amount_added);
      total_cfu += Number(item.total_cfu);
    });

    var factor = formulation_factor !== 0 ? formulation_factor : this.state.formulation_factor;
    this.setState({
      organisms,
      total_final_concentration: total_cfu,
      total_amount_added: total_amount_added,
      gain: isNaN(total_cfu / total_amount_added)
        ? 0 : total_cfu / total_amount_added,
      total_final_amount_added: factor * total_amount_added,
      formulation_factor: factor,
      formulation_organism,
    });
  };
  manual_add_organism = (value, inventory_id, barcode) => {
    if (inventory_id == undefined) {
      alert("Please enter the Organism");
      return;
    }
    var inventory_info = this.state.converted_reqd_inventory_fields_json[inventory_id];

    var qty = inventory_info.qty;
    let demo_qty = qty;
    let scrap_factor = inventory_info.scrap_factor;

    let prime_Section_value = 0;
    let prime_section_scrap_amount = 0;

    if (
      (Object.keys(prime_section_arr).length !== 0 &&
        inventory_id === prime_section_arr["id"]) &&
      this.state.is_prime_added
    ) {
      prime_Section_value = this.state.prime_filler_amount_added !== "" ? Number(this.state.prime_filler_amount_added) : 0;

      prime_section_scrap_amount = this.state.prime_filler_amount_added !== "" ? Number(this.state.prime_filler_scrap_amount) : 0;

      let x = value;
      if (x + Number(prime_Section_value) + Number(prime_section_scrap_amount) < qty) {
        qty = qty - (Number(prime_Section_value) + Number(prime_section_scrap_amount)).toFixed(2);
      } else {
        qty -= ((Number(prime_Section_value) + Number(prime_section_scrap_amount))).toFixed(2);
      }
    }

    prime_Section_value += prime_section_scrap_amount
    this.state.formulation_organism && this.state.formulation_organism.map((item) => {
      if (item.inventory.id == inventory_id) {
        prime_Section_value += item.final_amount_added;
        prime_Section_value += item.scrap_amount;
      }
    });

    this.state.organisms && this.state.organisms.map((ele) => {
      if (ele.inventory_id == inventory_id) {
        prime_Section_value += ele.final_amount_added;
        prime_Section_value += Number(ele.scrap_amount);
      }
    })


    var compare_value = value * this.state.formulation_factor;
    let scrap_amount = (Number(getScrapAmount(scrap_factor, compare_value))).toFixed(2);

    compare_value += Number(scrap_amount) + Number(prime_Section_value);
  
    if (compare_value > qty && compare_value > demo_qty) {
      qty = qty / this.state.formulation_factor;
      var demoQty = qty;

      scrap_amount = (Number(getScrapAmount(scrap_factor, qty))).toFixed(2)

      qty -= scrap_amount;

      scrap_amount = (Number(scrap_amount * this.state.formulation_factor)).toFixed(2)
      value = (Number(qty)).toFixed(2);
      this.setState({
        is_amount_added_exceeding_qty: true,
        check: demoQty
      })

    }
    this.userInputArr = [...this.userInputArr, barcode];
    type = barcode.split("_")[0];
    if (type === "FOR") {
      this.fetchData("for", this.userInputArr);
    } else if (type === "PRE" || type === "PRO") {
      this.fetchData("pre,pro", this.userInputArr);
    }

    var organisms = this.state.organisms;
    var formulation_organism = this.state.formulation_organism;

    var total_amount_added = 0;
    var total_cfu = 0;

    organisms && organisms.map((item) => {
      if (item.organism_barcode == barcode) {
        item.amount_added = value;
        item.final_amount_added = value * this.state.formulation_factor;
        item.final_concentration = item.amount_added * item.concentration;
        item.total_cfu = value * item.concentration;
        item.scrap_amount = scrap_amount;
      }
      total_amount_added += Number(item.amount_added);
      total_cfu += Number(item.total_cfu);
    });



    formulation_organism && formulation_organism.map((item) => {
      total_amount_added += Number(item.amount_added);
      total_cfu += Number(item.total_cfu);
    });
    var factor = formulation_factor !== 0 ? formulation_factor : this.state.formulation_factor;
    this.setState({
      organisms,
      total_final_concentration: total_cfu,
      total_amount_added: total_amount_added,
      gain: isNaN(total_cfu / total_amount_added)
        ? 0 : total_cfu / total_amount_added,
      total_final_amount_added: factor * total_amount_added,
      formulation_factor: factor,
      formulation_organism,
    });

  };
  getTagDataFromParent = (msg, value) => {
    if (!msg) {
      toast.warning("Not Available")
      return;
    }
    var flag = false;
    var tempArray_tag = [...this.state.organisms];
    if (this.preAdddedOrganismArr.indexOf(value.organism_barcode) > -1) {
      flag = true;
    }
    if (flag) {
      toast.info("Ingredient already exist");
      return;
    }
    if (this.state.converted_reqd_inventory_fields_json[value.inventory_id] === undefined) {
      toast.info("The ingredient is not allowed to add as you already selected other type of ingredients in this formula.");
      return
    }
    let qty =
      this.state.converted_reqd_inventory_fields_json[value.inventory_id].qty;

    /* Task Improve inventory management - part 2 Start */

    let prime_Section_value = 0;
    if (
      (Object.keys(prime_section_arr).length !== 0 &&
        value.inventory_id === prime_section_arr["id"])
      && this.state.is_prime_added
    ) {
      prime_Section_value = prime_section_arr["value"] !== "" ? prime_section_arr["value"] : 0;
      prime_Section_value += this.state.prime_filler_scrap_amount !== "" ? Number(this.state.prime_filler_scrap_amount) : 0;
    }
    this.state.formulation_organism && this.state.formulation_organism.map((item) => {
      if (item.inventory_id == value.inventory_id) {
        prime_Section_value += item.final_amount_added;
        prime_Section_value += item.scrap_amount;
      }
    });

    this.state.organisms && this.state.organisms.map((item) => {
      if (item.inventory_id == value.inventory_id) {
        prime_Section_value += item.final_amount_added;
        prime_Section_value += item.scrap_amount;
      }
    })
    /* Task:- Improve inventory management - part 2 End */

    let scrap_factor = getScrap(this.state.converted_reqd_inventory_fields_json, value.inventory_id);

    var x = value.amount_added
    let scrap_amount = getScrapAmount(scrap_factor, x);
    x += Number(scrap_amount) + Number(prime_Section_value);
    // when user qty excided to  required qty

    if (x > qty) {
      /* Note:-  when user input excided then pop show and then prime section amount deduct and after that find the scrap amount */
      var demoQty = qty -= Number(prime_Section_value);
      qty = qty;
      scrap_amount = (Number(getScrapAmount(scrap_factor, qty))).toFixed(2);
      qty -= Number(scrap_amount);
      // calculate value on formulation_factor
      value.amount_added = qty;
      this.setState({
        is_amount_added_exceeding_qty: true,
        check: demoQty,
        check_insert: value.amount_added,
      });
    }
    let selectedtag = [];
    let data
    let selected_tags_arr = [];
    if (Object.keys(value.tags).length > 0) {
      value.tags.map((item) => {
        selected_tags_arr.push({
          label: item.tag_name,
          value: item.tag_name,
          id: item.tag_id,
        })
      })
    }


    tempArray_tag.push({
      inventory_id: value.inventory_id,
      organism_barcode: value.organism_barcode,
      amount_added: value.amount_added,
      concentration: value.concentration,
      final_concentration: value.final_concentration,
      final_amount_added: this.state.formulation_factor * value.amount_added,
      total_cfu: value.amount_added * value.concentration,
      scrap_amount: (value.amount_added == 0) ? 0 : scrap_amount,
      tags: (selected_tags_arr.length > 0) ? selected_tags_arr : [],
      selected_tags: (selected_tags_arr.length > 0) ? selected_tags_arr : [],
      total_final_amount_add_calculation: value.amount_added,
      nonSelectedTag: this.state.multiSelectData,
    });

    // this.calculatevalue(tempArray_tag);
    var total_amount_added = 0;
    var total_cfu = 0;

    let organisms = [...this.state.organisms];
    organisms.map((items) => {
      total_amount_added += Number(items.amount_added);
      total_cfu += Number(items.total_cfu);
    });

    total_amount_added +=
      this.state.pendding_amount_added + Number(value.amount_added);
    total_cfu += this.state.pendding_total_cfu + Number(value.total_cfu);

    this.setState({
      total_final_concentration: total_cfu,
      total_amount_added: total_amount_added,
      gain: isNaN(total_cfu / total_amount_added) ? 0 : total_cfu / total_amount_added,
      total_final_amount_added:
        this.state.formulation_factor * total_amount_added,
    });

    this.setState({
      organisms: tempArray_tag,
    });
    // notification for success
    toast.success("Ingredient added");

    this.userInputArr = [...this.userInputArr, value.organism_barcode];
    this.preAdddedOrganismArr = [...this.preAdddedOrganismArr, value.organism_barcode]
    type = value.organism_barcode.split("_")[0];
    if (type === "FOR") {
      this.fetchData("for", this.userInputArr);
    } else if (type === "PRE" || type === "PRO") {
      this.fetchData("pre,pro", this.userInputArr);
    }

  }
  getMultiSelectTask = (id) => {

    axios.post(`${baseURL}/get-formula-tags/` + id, { "sg_number": "" }, getHeaders()).then(res => {
      if (res.data.success === false) {
        if (res.data.data == "Record not found") {
          this.setState({
            multiSelectData: [],
          });
        }
        this.isPageLoading(false);
      }
      else {
        let emptyArray = [];
        res.data.data &&
          res.data.data.map((elem, index) => {
            let obj = {
              label: elem.tag.tag_name,
              value: elem.tag.tag_name,
              id: elem.tag.tag_id,
            };
            emptyArray.push(obj);
          });
        this.setState({
          multiSelectData: emptyArray,
        });

      }
    })
      .catch((err) => {
        toast.error("Something Goes worng");
      })

  }

  onSelect = (selectedList) => {
    if (selectedList && selectedList.length) {
      this.setState({ tags: selectedList });
    } else {
      this.setState({ tags: [] });
    }
  };

  changeTagOnRefillOrganism = (selectedList, organism_id) => {
    var formulation_organism = this.state.formulation_organism;
    formulation_organism && formulation_organism.map((item) => {
      if (item.id == organism_id) {
        item.tags = selectedList;
      }
    })

    this.setState({ formulation_organism })
  }
  changeTagOnManualWayOrganism = (selectedList, organism_id) => {
    var changeTagOnManualWayOrganism = this.state.organisms;
    changeTagOnManualWayOrganism && changeTagOnManualWayOrganism.map((item) => {
      if (item.inventory_id == organism_id) {
        item.tags = selectedList;
      }
    })

    this.setState({ changeTagOnManualWayOrganism })
  }

  isPageLoading = (value) => this.setState({ is_page_loading: value })
  loader_on_select_sample = (value) => {
    this.setState({ btn_disable: value });
  }

  render() {
    var x = this.state.flourish_capsule_size === "powder" ? 83 : 45;
    total_amount_added = Number(this.state.formulation_factor) * Number(x);
    return (
      <React.Fragment>
        {this.state.is_page_loading && <CustomLoader />}

        <CustomDimmer
          active={this.state.is_prime_filler_amount_added_exceeding_qty}
          title="Not Enough Quantity!"
          pre_content="Organism Inventory only has ("
          content={this.state.prime_filler_amount_added}
          post_content=" g) of this Prime Filler Organism."
          //methods
          onClickOutside={this.onClickOutside}
        />

        <CustomDimmer
          active={this.state.is_amount_added_exceeding_qty}
          title="Not Enough Quantity!"
          pre_content="Organism Inventory only has ("
          content={this.state.check}
          post_content=" g) of this Organism."
          //methods
          onClickOutside={this.onClickOutside}
        />

        {this.state.is_saving_successful && (
          <SuccessMessage
            is_message_visible={this.state.is_message_visible}
            hideMessage={this.hideMessage}
            module_name="Formulations"
            message={this.state.response_message}
          />
        )}

        {this.state.is_saving_failed && (
          <ErrorMessage
            is_message_visible={this.state.is_message_visible}
            hideMessage={this.hideMessage}
            module_name="Formulations"
            message={this.state.response_message}
          />
        )}
        <br />
        <Message id="create_fomulation_message">
          <Header textAlign="center">
            {Config.formulations.section_title.createReformulation}
          </Header>
        </Message>

        <Segment compact className="transparent">
          {this.state.is_waiting_for_response && (
            <CustomLoader
              loading_text={
                this.state.waiting_message == ""
                  ? "Saving Formulation Data"
                  : this.state.waiting_message
              }
            />
          )}

          {this.state.is_popUp_disabled && (
            <ShowModal
              message={warning}
              Description={popUp_description}
              Modal_return_value={this.Modal_return_value}
              pop_up_open={this.pop_up_open}
            />
          )}

          <Form>
            <ReFormulationInfoRefill
              id="formulation_info_refill"
              sample_id={sample_Id}
              formula_id={this.state.formula_id}
              date_created={this.state.date_created}
              loader_on_select_sample={this.loader_on_select_sample}
              number_of_months={this.state.number_of_months}
              formulation_factor={this.state.formulation_factor}
              admin_notes={this.state.admin_notes}
              form_errors={this.state.form_errors}
              disabledPermission={this.state.disabledPermission}
              onDateChange={this.onDateChange}
              onChange={this.onInputChange}
              product_type={this.state.product_type}
              handlerProductType={this.handlerProductType}
              handlerPayementType={this.handlerPayementType}
              handlerAssign={this.handlerAssign}
              flore_probiotics={this.flore_probiotics}
              reformulationId={this.reformulationId}
              payementOptions={payementOptions}
              payment_type={this.state.payment_type}
              reformulation_notes={this.reformulation_notes}
              changeLoadingStateValue={this.changeLoadingStateValue}
              component={"reformulation"}
              getTagDataFromParent={this.getTagDataFromParent}
              isPageLoading={this.isPageLoading}
              is_number_of_month_disabled = {this.state.is_number_of_month_disabled}
            />

            {/* . */}
            {/* Prime */}
            {/* . */}

            <PrimeRefill
              prime_filler_inventory_id={this.state.prime_filler_inventory_id}
              prime_flourish_amount_added={
                this.state.prime_flourish_amount_added
              }
              prime_filler_amount_added={this.state.prime_filler_amount_added}
              prime_total_amount_added={this.state.prime_total_amount_added}
              is_prime_added={this.state.is_prime_added}
              disabledPermission={this.state.disabledPermission}
              form_errors={this.state.form_errors}
              //methods
              onChange={this.onInputChange}
              changePrimeAddition={this.changePrimeAddition}
              //json
              inventory_options={this.state.inventory_options_prime}
              selectCapsuleSizeFunction={this.change}
              prime_capsule_size={this.state.prime_capsule_size}
              options={options}
              prime_filler_scrap_amount={this.state.prime_filler_scrap_amount}
              isPageLoading={this.isPageLoading}
              is_prime_added_disabled = {this.state.is_prime_added_disabled}
            />

            {/* . */}
            {/* Flourish */}
            {/* . */}
            <Flourish
              number_of_months={this.state.number_of_months}
              formulation_factor={this.state.formulation_factor}
              //organisms related params
              organisms={this.state.organisms}
              organism_barcode={this.state.organism_barcode}
              inventory_id={this.state.inventory_id}
              amount_added={this.state.amount_added}
              concentration={this.state.concentration}
              final_concentration={this.state.final_concentration}
              scrap_amount={this.state.scrap_amount}
              final_amount_added={this.state.final_amount_added}
              total_cfu={this.state.total_cfu}
              disabledPermission={this.state.disabledPermission}
              refillAmountAdded={this.state.refillAmountAdded}
              refillInventory_id={this.state.refillInventory_id}
              refillInventory_id0={this.state.refillInventory_id0}
              refillInventory_id1={this.state.refillInventory_id1}
              refillInventory_id2={this.state.refillInventory_id2}
              refillInventory_id3={this.state.refillInventory_id3}
              refillamountAdded={this.props.refillamountAdded}
              //methods
              onChange={this.onInputChange}
              onDeleteRefillOrganismClick={this.onDeleteRefillOrganismClick}
              onAddOrganismClick={this.onAddOrganismClick}
              onDeleteOrganismClick={this.onDeleteOrganismClick}
              manual_add_organism={this.manual_add_organism}
              //json
              inventory_options={this.state.inventory_options}
              available_inventory_json={this.state.available_inventory_json}
              formulation_organism={this.state.formulation_organism}
              sampleData={sampleData}
              selectCapsuleSizeFunction={this.change}
              flourish_capsule_size={this.state.flourish_capsule_size}
              options={options}
              changeStructureFunction={this.changeStructureFunction}
              onChangeOf_organism={this.onChangeOf_organism}
              multiSelectData={this.state.multiSelectData}
              onSelect={this.onSelect}
              multiselectRef={this.multiselectRef}
              changeTagOnRefillOrganism={this.changeTagOnRefillOrganism}
              changeTagOnManualWayOrganism={this.changeTagOnManualWayOrganism}
              is_number_of_month_disabled={this.state.is_number_of_month_disabled}
            />

            <TotalRefillInfo
              total_amount_added={this.state.total_amount_added}
              total_final_concentration={this.state.total_final_concentration}
              total_final_amount_added={this.state.total_final_amount_added}
              disabledPermission={this.state.disabledPermission}
              gain={this.state.gain}
              module_name_total_info={this.state.module_name_total_info}
              btn_disable={this.state.btn_disable}

              //methods
              onSubmitClick={this.confirm_value}
              onCancelClick={this.onCancelClick}
            />
            <Grid columns="equal" />
          </Form>
        </Segment>
      </React.Fragment>
    );
  }
}

export default CreateReformulation;
