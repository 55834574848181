//-------------------------------------------------------------------//
// Module: Formulations                                              //
// SubModule: CreateFormulation                                      //
// SubSubModule: FormulationInfo                                     //
// Tasks: 1) Receives and displays FormulationInfo                   //
//        2) Fetches nextSampleIDs and formulaIDs                    //
// Author: Yash P Shah                                               //
//-------------------------------------------------------------------//

import Config from "../../configuration/config";
import { customPrint, getHeaders, getSessionStoredJsonItem, } from "../helper";
import { baseURL, PRINT_CATEGORIES, portalURL } from "../../configuration/constants";
import CustomDivider from "../custom_elements/CustomDivider";
import React, { Component } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import { Form, Input, Grid, TextArea, Label, Segment, Popup, Modal, Button, Header, Icon, Accordion, Message } from "semantic-ui-react";
import { onGridReady } from "../helper";
import { AgGridReact } from "ag-grid-react";
import { toast } from "react-toastify";
import { PastFormulations } from "../formulation_rationale/NewPastFormulation";
import CustomerProfileNotesReformulation from "./CustomerProfileNotesReformulation";
import ReformulationNotes from "./ReformulationNotes";
import ReformulationForm from "./ReformulationForm";
import StartFormulationModals from "../inventory_mod/NewFormulation/start_formulation/StartFormulationModal";
import { Product_Type } from "../helper";

export { selectedSample, selectedFormula, sampleData, rowSelectValue, InQueueSample };
export { selectedProduct };

let api, column_api, selectedSample, selectedProduct, selectedFormula, sampleData, rowSelectValue, InQueueSample;

var reformulation_logs = [];
var reformulation_logs_exists = null;

let profile_notes = [];
let notes = [];
let reformulation_details = [];


class FormulationInfoRefill extends Component {
  is_mounted = false;
  
 

  state = {
    newTableId: "",
    samples: [],
    modalOpen: false,
    next_sample_ids_json: [],
    formula_ids_json: [],
    setFormHeight:"",
    pastFormulationSampleId:"",
    showPastFormula:false,
    timepointList:[],
    old_formulation_id:'',
    old_sample_id:'',
    formulation_id:'',
    formula_type:"",
    start_formulation_popup:false,
    columnDefs:

      [
        {
          headerName: "Sample ID",
          field: "sample_id",
          sizeColumnToFit: true,
          filter: "agTextColumnFilter",
          sortable: true,
          width:150,
        },
        {
          headerName: "Formula ID",
          field: "formula_id",
          sizeColumnToFit: true,
          filter: "agTextColumnFilter",
          sortable: true,
          width:150,
        },
        {
          headerName: "Date Created",
          field: "date_created",
          sizeColumnToFit: true,
          filter: "agTextColumnFilter",
          sortable: true,
          width:150,
        },
        {
          headerName: "Date Made",
          field: "date_made",
          sizeColumnToFit: true,
          filter: "agTextColumnFilter",
          sortable: true,
          width:100,
        },
        {
          headerName: "Age",
          field: "age",
          sizeColumnToFit: true,
          filter: "agTextColumnFilter",
          sortable: true,
          width:100,
        },
        {
          headerName: "Product Type",
          field: "product_type",
          sizeColumnToFit: true,
          filter: "agTextColumnFilter",
          sortable: true,
          width:250,
        },
        {
          headerName: "Type",
          field: "type",
          sizeColumnToFit: true,
          filter: "agTextColumnFilter",
          sortable: true,
          width:150,
        },

      ],
      survey:[],
      activeIndexs:[0]
  };

  componentDidMount() {

    this.getSamples();
    this.is_mounted = true;
    InQueueSample = "";
    customPrint(
      Config.formulations.section_title.create + " <FormulationInfo>",
      PRINT_CATEGORIES.REQUEST,
      baseURL + "/formulations/formulaIds"
    );
    axios
      .get(baseURL + "/formulations/formulaIds", getHeaders())
      .then(response => {
        customPrint(
          Config.formulations.section_title.create + "<FormulationInfo>",
          PRINT_CATEGORIES.RESPONSE,
          response
        );

        let data = response.data;

        let formula_ids_json = [];
        if (typeof data === "object") {
          data.forEach(elem => {
            formula_ids_json.push({ title: elem });
          });
          if (this.is_mounted) {
            this.setState({
              formula_ids_json,
              is_page_loading: false
            });
          }
        }
      })
      .catch(error => {
        alert("Fetching Formula ID | Error: " + error);
        if (this.is_mounted) {
          this.setState({ is_page_loading: false });
        }
      });
  }
  getSamples = () => {
    let user_object = getSessionStoredJsonItem("user");
    let userId = user_object.user_id;
    axios.get(baseURL + "/formulations/getAllRecords/" + userId, getHeaders())
      .then(res => {
        let data = res.data;
        return data;
      })
      .then(samples => {

        if (samples.length > 0) {
          this.setState({
            samples: samples[0],
            newTableId: samples.id
          });
        }
      });
  };

  handleSelectedSample = async (sample) => {
    this.setState({
      old_sample_id:sample.sample_id,
      old_formulation_id:sample.formula_id,
      formulation_id:sample.id,
      start_formulation_popup:true,
      formula_type:sample.formula_type
    });
    this.props.loader_on_select_sample(true);

    this.GetHealthAndDietSurvey(sample.sample_id);
    this.setState({ deletePermission: false, setFormHeight: "", pastFormulationSampleId: sample.sample_id, showPastFormula: false, timepointList: [] })
    axios.post(portalURL + "/getTimePoint", { token: Config.token, sg_number: sample.sample_id })
    .then((result) => {
      if (result.data.data !== null) {
        this.setState({ timepointList: result.data.data.timepoint_list });
        this.getCustomerProfileNotes(result.data.data.timepoint_list);
      }
      else {
        this.props.isPageLoading(false);
      }
    })

    this.wepPortalApi(sample.sample_id)
    axios.get(baseURL + "/getrefillData/" + sample.id, getHeaders())
      .then(res => {
        let sampleData = res.data[0];
        this.props.handlerAssign(sampleData)
      })
    let formulaIds;
    selectedSample = sample["sample_id"];
    selectedProduct = sample["product_type"]
    this.props.handlerProductType(selectedProduct)
    var getFormulaLastPart = sample["formula_id"].split("_");

    let increment = parseInt(getFormulaLastPart[2]) + 1;
    let number = String(increment).padStart(3, '0')
    let newFormulaId = getFormulaLastPart[0] + "_" + getFormulaLastPart[1] + "_" + number;

    axios
    .get(baseURL + "/getQueueRecords", getHeaders())
    .then(response => {
      var flag = true;
      response.data.forEach(details => { 
        if (details.sample_id == sample["sample_id"] && details.product_type == Product_Type[sample["product_type"]]) {
          InQueueSample = details.sample_id;
          this.setState({ modalOpen: true });
          flag = false;
        }
        
      })

      if(!flag){
        InQueueSample  = " ";
      }

    })

    axios
      .get(baseURL + "/formulations/getRefillRecordsforFormulaId", getHeaders())
      .then(result => {
        formulaIds = result.data;
        newFormulaId = this.createAutoIncFormula(formulaIds, newFormulaId);
        selectedFormula = newFormulaId;
        this.setState({
          sample_id: sample["sg_number"],
          formula_id: newFormulaId,
        });



      })
    axios.get(baseURL + "/getrefillQueueData/" + sample.id + "?type=refill_queue", getHeaders())
      .then(res => {
        // check reformaulation logs exists or not 
        if (res.data[0].reformulation_logs_exist !== null) 
        {
          reformulation_logs = res.data[0].reformulation_logs
          reformulation_logs_exists = res.data[0].reformulation_logs_exist
        }
        // this.props.changeLoadingStateValue(false);
        else {
          reformulation_logs_exists = null;
          reformulation_logs = [];
        }
        // this.props.changeLoadingStateValue(false, "");
      })

    return selectedSample;

  }

  wepPortalApi = (value) => {
    let data = {
      sg_number: value,
      token: Config.token
    }
    axios
      .post(portalURL + "/getKitProductType", data)
      .then(response => {
        this.props.flore_probiotics(response.data.data.flore_probiotics)
      })
  }

  createAutoIncFormula(formulaIds, newFormulaId) {
    for (var i = 0; i < formulaIds.length; i++) {
      formulaIds.forEach(res => {
        if (res.formula_id === newFormulaId) {
          var getFormulaLastPart = newFormulaId.split("_");
          let increment = parseInt(getFormulaLastPart[2]) + 1;
          let number = String(increment).padStart(3, '0')
          newFormulaId = getFormulaLastPart[0] + "_" + getFormulaLastPart[1] + "_" + number;
        }
      })
    }
    this.props.loader_on_select_sample(false);

    return newFormulaId;

  }
  componentWillUnmount() {

    this.is_mounted = false;
  }
  handleOpen = (e) => {
    this.setState({
      modalOpen: true,
    })
  }

  handleClose = () => {
    this.setState({ modalOpen: false })
  }

  handleChange = (e) => [
    this.props.handlerPayementType(e.target.value)
  ]

  GetHealthAndDietSurvey = (selectedSample) => {
    // this.props.isPageLoading(true);
    let payload = {
      sg_number: selectedSample,
      // sg_number: "DB_2233",
      token: Config.token
    };
    axios.post(`${portalURL}/getHealthDietSurvey`, payload).then(res => {
      if (res.data.data == [] || res.data.data == "") {
        this.setState({
          survey: [],
        })
      }
      else {
        let surveyData = res.data.data;
        if (surveyData.length > 0) {
          this.setState(
            {
              survey: surveyData,
              setFormHeight: "reformulation"
            },
          );
          // this.props.isPageLoading(false);
        }
      }
    });
  }

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndexs } = this.state;

    const newIndex = [];

    const currentIndexPosition = activeIndexs.indexOf(index);
    var x = false;
    if (currentIndexPosition > -1) {
      newIndex.splice(currentIndexPosition, 1);
      x = true;
    }
    if (!x) {
      newIndex.push(index);
    }
    this.setState({ activeIndexs: newIndex });
  };

  generateSurveyText = (activeIndexs) => {
    return (
      <div>
        {this.state.survey.length > 0 && this.state.survey.map((item, index) => {
          return <Accordion>
            <Accordion.Title
              active={activeIndexs.includes(0)}
              index={index}
              onClick={this.handleClick}
            >
              <Icon name="dropdown" />
              {item.sg_number}
            </Accordion.Title>
            <Accordion.Content active={activeIndexs.includes(index)}>
              <ol style={{ marginTop: "-3px" }}>

                {item.survey_data.map((item, index) => (
                  <li
                    key={index}
                    className="formulation-rationale__health-survey__item"
                  >
                    <b>{item.headings[0].heading}</b>
                    {item.family === "open_ended" ? (
                      <p>{item.answers}</p>
                    ) : (item.family === "matrix" ? (
                      <div>
                        {item.answers.map((answer, index) => (
                          <div key={index}>
                            <p>{answer.choicesVal}</p>
                          </div>
                        ))}
                      </div>
                    ) : (<div> {item.answers.map((answer, index) => (<div key={index}> <p>{answer.text}</p> </div>))}
                    </div>))}
                  </li>
                ))}
              </ol>
            </Accordion.Content>
          </Accordion>
        })}

      </div>
    );
  };

  set_page_loading = (value) => {
    this.setState({ is_page_loading: value })
    this.props.isPageLoading(value);
    setTimeout(() => {
      window.scrollTo({
        top: 700,
        behavior: 'smooth',
      })
    }, 500);

  }
  

  changeStatus(value) {
    this.props.isPageLoading(value);
  }

  pastFormulation = () => {
    this.setState({ showPastFormula: true });
    this.props.isPageLoading(true);  
  }

  getCustomerProfileNotes = (timepointList) => {
    var sg_number_list = [];

    timepointList && timepointList.map((list_sg_number) => {
      sg_number_list.push(list_sg_number.sg_number);
    })

     this.props.isPageLoading(true);
    axios
      .get(`${baseURL}/formulations/getAllNotes?sg_number=${sg_number_list}`, getHeaders())
      .then(res => {
        profile_notes = res.data.customer_notes;
        notes = res.data.notes
        reformulation_details = res.data.reformulation_details
        this.props.isPageLoading(false);
        this.setState({ setFormHeight: "reformulation" })

      }).catch(() => {
        this.props.isPageLoading(false);
      });

  }

  render() {
    let sgnumber = "";
    sgnumber = this.props.sample_id;
    if (sgnumber == null) {
      sgnumber = "";
    }
    const { activeIndexs } = this.state;
    let surveyText = this.generateSurveyText(activeIndexs);
  
    return (

      <Grid columns="equal">
        <CustomDivider
          h_size="h3"
          title="Formula Information"
          icon_name="calculator"
        />
         <Button
          basic
          onClick={() => this.pastFormulation()}
          icon
          labelPosition="right"
          disabled={this.state.timepointList.length == 0}
          style={{ marginLeft: "15px" }}
        >
          Previous Formulation
          <Icon name="eye" />
        </Button>

      {/* start formulation */}
        {(this.state.start_formulation_popup) && (
          <div className="col-filter" >
              <StartFormulationModals
                  sample_id={this.state.old_sample_id}
                  formula_id={this.state.old_formulation_id }
                  formulation_id={this.state.formulation_id}
                  is_disabled={true}
                  call_from = {"refill"}
                  formula_type = {this.state.formula_type}
              />
          </div>
       )}

        &nbsp; &nbsp; &nbsp;
        <Grid.Row>
          <Grid.Column>
            <Segment
              compact
              raised
              padded
              style={{ display: "block", marginTop: "0" }}
            >
              <Grid columns="equal">
                <Grid.Row>
                  <Grid.Column>
                    <div
                      id="myGrid"
                      className="ag-theme-balham"
                      ag-grid="gridOptions"
                      style={{
                        height: "100%",
                        width: "100%"
                      }}
                    >
                      <AgGridReact
                        rowHeight="30"
                        rowSelection="single"
                        onRowClicked={e => {
                          // if (!this.props.disabledPermission) {
                          let sample = e.data;
                          this.handleSelectedSample(sample);
                          // }
                        }}
                        onGridReady={params => {
                          api = params.api;
                          api.showLoadingOverlay();
                          api.sizeColumnsToFit();
                          column_api = params.column_api;
                          onGridReady(
                            params,
                            api,
                            column_api,
                            this.props.initial_sort
                          );
                        }}
                        animateRows={true}
                        pagination={true}
                        paginationPageSize={10}
                        paginationNumberFormatter={params => {
                          return "[" + params.value.toLocaleString() + "]";
                        }}

                        columnDefs={this.state.columnDefs}
                        floatingFilter={true}
                        rowData={this.state.samples}
                      />
                      {this.state.modalOpen == true ?
                        <Modal
                          id="modelpopup"
                          // trigger={<Button onClick={() => this.handleOpen(this.props.selected_row_value.data.sg_number)}>Previous Formula</Button>}

                          open={this.state.modalOpen}
                          onClose={this.handleClose}
                          size='Mini'
                        >
                          <Header align="center" />
                          <Modal.Content  >
                            {"Already exists in formulation queue page either take another sg number or make formulation of this sgnumber in formulation queue page"}
                          </Modal.Content>
                          <Modal.Actions>
                            <Button color='blue' onClick={this.handleClose} >
                              OK
                            </Button>
                          </Modal.Actions>
                        </Modal>
                        : null
                      }
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </Grid.Column>
        </Grid.Row>

          {this.state.showPastFormula &&
          <PastFormulations
            ReformulationInfo={true}
            sg_number={this.state.pastFormulationSampleId}
            selectedSample={this.state.timepointList}
            set_page_loading={this.set_page_loading}
            getTagDataFromParent={this.props.getTagDataFromParent}
            changeStatus={this.changeStatus}
            />}

          <Grid.Row>
          <Grid.Column>
            <ReformulationForm
              reformulation_logs={reformulation_logs}
              reformulation_logs_exists={reformulation_logs_exists}
              component={this.state.setFormHeight}
            />

            {(this.state.setFormHeight !== "") && (
              <>
                <Message
                  className="formulation-rationale__health-survey__header"
                  style={{ marginBottom: "0" }}
                >
                  <Header as="h2" textAlign="center">
                    Health Survey
                  </Header>
                </Message>
                <Segment
                  style={{
                    marginTop: "0",
                    marginBottom: "0",
                  }}

                  className="formulation-rationale__health-survey"
                >
                  {surveyText}
                </Segment>
              </>

            )
            }

          </Grid.Column>
          <Grid.Column>
              <div>

                <CustomerProfileNotesReformulation
                  profile_notes={profile_notes}
                  notes={notes}
                  refill={true}
                />
              </div>

          </Grid.Column>
        </Grid.Row>


        <Grid.Row>
          <Grid.Column>
            <Form.Field required fluid="true">
              <Popup
                trigger={<label>Sample ID </label>}
                content="Enter the Sample ID."
              />
              <Input
                id="sample_id"
                name="sample_id"
                onChange={this.props.onChange}
                value={selectedSample ? selectedSample : sgnumber}
                disabled={(this.props.disabledPermission || selectedSample) ? true:false}
                placeholder="Sample ID"
                // disabled={selectedSample ? true : false}
              />
            </Form.Field>
          </Grid.Column>
          <Grid.Column>
            <Form.Field fluid="true">
              <Popup
                trigger={<label>Product Type</label>}
                content="Enter the Product Type."
              />
              <Input
                value={selectedProduct}
                placeholder="Product Type"
                disabled={selectedProduct ? true : false}
              />
            </Form.Field>
          </Grid.Column>

          <Grid.Column>
            <Form.Field required fluid="true">
              <Popup
                trigger={<label>Date Created</label>}
                content="Enter Date Created."
              />
              <DatePicker
                selected={this.props.date_created}
                placeholderText="Click to select the Date Created"
                todayButton={"Current Date"}
                dateFormat="MM/dd/yyyy"
                isClearable={true}
                disabled={this.props.disabledPermission}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                openToDate={new Date(Config.default_calendar_date)}
                onChange={date => {
                  this.props.onDateChange(
                    Config.formulations.date_created,
                    date
                  );
                }}
              />
            </Form.Field>
          </Grid.Column>

          <Grid.Column  >
            <Form.Field fluid="true"  >
              <Popup
                trigger={<label>Formula ID</label>}

                content="Enter the Formula ID."
              />
              <Input
                id="formula_id"
                name="formula_id"
                onChange={this.props.onChange}
                // value={this.props.formula_id}
                value={selectedFormula ? selectedFormula : this.props.formula_id}
                placeholder="Formula ID "
                disabled={selectedFormula ? true : false}
              />
            </Form.Field>
          </Grid.Column>

          <Grid.Column>
            <Form.Field required fluid="true">
              <Popup
                trigger={<label>Number of Months</label>}
                content="Enter the Number of Months."
              />
              <Input
                name="number_of_months"
                type="number"
                onChange={this.props.onChange}
                value={this.props.number_of_months}
                icon="calendar alternate outline"
                iconPosition="left"
                placeholder="Number of Months"
                disabled={this.props.disabledPermission || this.props.is_number_of_month_disabled}
              />
              {this.props.form_errors.number_of_months && (
                <Label basic color="red" pointing>
                  {this.props.form_errors.number_of_months}
                </Label>
              )}
            </Form.Field>
          </Grid.Column>
          <Grid.Column>
            <Form.Field fluid="true">
              <Popup
                trigger={<label>Payment</label>}
                content="Enter the Payment."
              />
              <select onChange={this.handleChange}
                value={this.props.payment_type}
              >
                {
                  this.props.payementOptions && this.props.payementOptions.map((elem, i) => {
                    return (
                      <option key={i}>
                        {elem.payment_type}
                      </option>

                    )
                  })
                }
              </select>
            </Form.Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Popup
              trigger={<label>Admin Notes</label>}
              content="Enter Admin notes."
            />
            <TextArea
              id="admin_notes"
              name="admin_notes"
              onChange={this.props.onChange}
              value={this.props.admin_notes}
              placeholder="Add Notes here..."
              style={{ minHeight: 100 }}
              disabled={this.props.disabledPermission}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row />
      </Grid>

    );
  }
}

export default FormulationInfoRefill;
